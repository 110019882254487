

/*请求地址配置*/
let HY_BASEURL = "";
let HYTX_SHAREURL = "";
if (process.env.NODE_ENV === 'development') {
	// dev 开发 run dev
	HY_BASEURL = "/baseUrl";
	HYTX_SHAREURL = "/shareWx";
} else {
	// build 线上和测试 run build
	HY_BASEURL = "";
	HYTX_SHAREURL = "";
}

// console.log('process.env',process.env);


const urlConfig = {
	// 商品发布 v2
	// HyCommodityRelease: HY_BASEURL + "/v2/bizShopGoods/publish",
	// 商品基本属性和销售属性查询接口
	// subjectId：必传,useType：必传（默认0）需要获取基本属性时为1 获取销售属性时为2
	// HyCommodityAttribute: HY_BASEURL + "/bizShopGoods/query/attr",
	// 数据字典
	// HyDataDictionaries: HY_BASEURL + "/dic/query/list",
	// 商品编辑查询接口,/v2/bizShopGoods/edit/query/{goodsNo},goodsNo：商品编号
	// HyCommodityEdit: HY_BASEURL + "/v2/bizShopGoods/edit/query",
	// 商品基本信息编辑更新接口,/v2/bizShopGoods/edit/{goodsId}/baseInfo,路径参数: goodsId：数据ID
	// HyCommodityBaseinfoUpdate: HY_BASEURL + "/v2/bizShopGoods/edit",
	// 商品售价和库存编辑更新接口  /v2/bizShopGoods/edit/{goodsNo}/skuPrice
	// HyCommodityPriceInventoryUpdate: HY_BASEURL + "/v2/bizShopGoods/edit",
	// 商品中间费用和折算编辑更新接口 /v2/bizShopGoods/edit/{goodsNo}/feeScale
	// HyCommodityMiddleFeeDiscountUpdate: HY_BASEURL + "/v2/bizShopGoods/edit",
	// 商品描述编辑更新接口， /v2/bizShopGoods/edit/{goodsId}/content，goodsId：商品数据ID
	// HyCommodityDescribeUpdate: HY_BASEURL + "/v2/bizShopGoods/edit",
	// 商品发货与物流接口
	// /v2/bizShopGoods/edit/{goodsNo}/{storeId}/logistics
	// HyCommodityDeliveryLogistics: HY_BASEURL + "/v2/bizShopGoods/edit",
	// 商品地址分页查询, /bizShopGoodsAddrees/query/{userId}/page/{useType}
	// HyGoodsAddreesPage: HY_BASEURL + "/bizShopGoodsAddrees/query",
	// 卖家中心-商品地址插入和编辑，/bizShopGoodsAddrees/edit/update/{useType}
	// HyGoodsAddreesPageAddEdit: HY_BASEURL + "/bizShopGoodsAddrees/edit/update",
	// 卖家中心-商品地址设置默认地址，/bizShopGoodsAddrees/edit/def
	// HyGoodsAddreesPageDefault: HY_BASEURL + "/bizShopGoodsAddrees/edit/def",
	// 卖家中心-商品地址删除，/bizShopGoodsAddrees/del
	// HyGoodsAddreesPageDel: HY_BASEURL + "/bizShopGoodsAddrees/del",
	// 卖家中心-商品地址编辑查询，/bizShopGoodsAddrees/edit/query/{id}
	// HyGoodsAddreesPageEdit: HY_BASEURL + "/bizShopGoodsAddrees/edit/query",
	// 卖家中心-商品地址查询所有， /bizShopGoodsAddrees/query/{userId}/all/{useType}
	// HyGoodsAddreesPageAll: HY_BASEURL + "/bizShopGoodsAddrees/query",

	// PC前端商品类表展示分页查询， /v2/bizShopGoods/query/show/page
	// HyGoodsClassifyQuery: HY_BASEURL + "/v2/bizShopGoods/query/show/page",
	// // 商品详情查询接口，  /v2/bizShopGoods/query/show/{goodsNo}/{clientType}
	// HyGoodsDetailQuery: HY_BASEURL + "/v2/bizShopGoods/query/show",
	// // HySpecificationSelectQuery: HY_BASEURL + "/bizShopGoodsSku/choose",
 //  HySpecificationSelectQuery: HY_BASEURL + "/app/v2/bizShopGoods/choose/sku",

 //  // 商品详情-经营模式查询， /v2/bizShopGoods/query/{sourceGoodsNo}/{business}/{clientType}
 //  HyGoodsDetailsByBusinessType: HY_BASEURL + "/v2/bizShopGoods/query",

 //  // 根据商品编号和类型查询费用折扣, /app/bizShopGoodsFee/query/fee/discount/{goodsNo}/{useType}
 //  HyGoodsDiscountQuery: HY_BASEURL + "/bizShopGoodsFee/query/fee/discount",

	// // 卖家中心-商品类表分页查询接口， /v2/bizShopGoods/query/sell/page
	// HySellerGoodsClassifyQuery: HY_BASEURL + "/v2/bizShopGoods/query/sell/page",

	//获取微信签名配置
	// HyWxConfigUrl: HYTX_SHAREURL + '/group/wx/signature',
	//手机号区域代码
	HyPhoneAreaCode: HY_BASEURL + "/dic/getAreaCode",
	//注册
	HyRegister: HY_BASEURL + "/bizUser/web/regin",
	//登录
	HyLogin: HY_BASEURL + "/bizUser/web/login",
	//验证码登录，不存在的用户，自动注册并登录
	HyMobileLogin: HY_BASEURL + "/app/bizUser/mobileLogin",
	//登出
	HyLoginOut: HY_BASEURL + "/bizUser/logout",
	//获得登录二维码
	HyGetQRCode: HY_BASEURL + "/app/bizQrCode/v2/getQrCode",
	//轮询调用，二维码登录
	HyLoginQRCode: HY_BASEURL + "/bizUser/web/loginByQrCode",
	//客服IP判定
	HyCustomerServiceIp: HY_BASEURL + "/bizHomeCommon/query/hostType",
	//获得认证信息
	getAuthData: HY_BASEURL + "/bizUser/read/detail",
	
	//修改密码 /bizUser/login/resetPassword POST {"password":"","confromPassword":""}
	HyBizUserPasswordModify: HY_BASEURL+"/bizUser/login/resetPassword",
	

	//合伙人公约，阅读情况
	//公约-已阅读提交
	updateParentRead: HY_BASEURL + "/bizPartner/update/readTreaty",
	//公约-查询
	getParentReadDet: HY_BASEURL + "/bizCommon/query/loginAfter/common/info",

	//搜索店铺，品名
	// HySerShopAndGoods: HY_BASEURL + "/bizEnterprise/searchEnterprise",
	//搜索商品
	// HySerShopAndGoodsList: HY_BASEURL + "/bizEnterprise/searchGoods",

	//搜索商品-新
	// HySerShopAndGoodsListNew: HY_BASEURL + "/bizSearch/goods",

	//卖家认证
	// selleAuth: HY_BASEURL+"/bizSeller/insert/authentication",
	//买家认证
	// buyerAuth: HY_BASEURL+"/bizBuyer/insert/authentication",
	//厂家认证
	// EnterpriseAuth: HY_BASEURL + "/bizEnterprise/insert/authentication",
	// //店铺认证
	// ShopAuth: HY_BASEURL + "/bizShop/insert/shopAuthentication",
	// //合伙人认证
	// partnerAuth: HY_BASEURL + "/bizPartner/insert/authentication",


	//金融服务资格申请
	// FinanceAuthentication: HY_BASEURL + "/bizFinanceEnterprise/insertEntity",
	// //金融认证查询
	// FinanceAuthenticationQuery: HY_BASEURL + "/bizFinanceEnterprise/queryByList",
	// //金融认证修改
	// FinanceAuthenticationUpdate: HY_BASEURL + "/bizFinanceEnterprise/updateEntity",
	// //厂家身份标签
	// UpdateEnterpriseTag: HY_BASEURL + "/bizEnterprise/updateEnterpriseTag",

	//验证手机号码
	verifiedPhoneNumber: HY_BASEURL + "/bizUser/verifiedPhoneNumber",
	//修改密码
	resetPassword: HY_BASEURL + "/bizUser/resetPassword",
	//修改手机号码
	resetPhoneNumber: HY_BASEURL + "/bizUser/resetPhoneNumber",
	//单纯验证手机号码
	validatePhoneOnly: HY_BASEURL + "/bizUser/validatePhoneOnly",
	//设置交易密码
	settingTransPwd: HY_BASEURL + "/bizOnlineAccount/pwd/setting",
	//设置交易密码
	modifyTransPwd: HY_BASEURL + "/bizOnlineAccount/pwd/update",
	//交易密码等信息
	AccountTransInfo: HY_BASEURL + "/bizOnlineAccount/query/open/acount/info",
	//交易密码的短信
	sendMessageAccountTrans: HY_BASEURL + "/bizOnlineAccount/send/msgCode",
	//设置交易密码是否开启
	settingTransState: HY_BASEURL + "/bizOnlineAccount/update/psd/state",

	//用户头像
	modifyUserHead: HY_BASEURL + "/bizUser/changeAvatar",

	//消息
	// HyMsgList: HY_BASEURL + "/platform/msg/biz/query/my",
	//消息红点//我的消息数
	// HyMyMsgCount: HY_BASEURL + "/platform/msg/biz/query/my/msg/count",

	//banner接口
	// BannerList: HY_BASEURL + "/bizHomeCommon/query/banner",
	//棉花节2019
	// cottonPlan2019:HY_BASEURL+"/tmpInpartEnterprise/insert",

	//活动信息，民生专栏
	// HyGetActivityNT: HY_BASEURL + "/activity/getActivityNTPC",

 //  //首页活动--新--同步移动
 //  HomeActivityDataNew: HY_BASEURL + '/sysActivityBaseConf/query/home/page',

 //  //厂家列表
 //  shopListData: HY_BASEURL + '/app/bizShop/v2/query/page/enterprise/shop',

	// //合伙人招募
	// PartnerRecruitment: HY_BASEURL + "/bizUser/web/recruitPartner",
	// //合伙人招募信息
	// PartnerRecruitmentList: HY_BASEURL + "/bizPartner/read/recruitPartnerList",

	// //合伙人获得当前邀请注册手机号(子账户为父账户合伙人id，主账户为当前登录人手机号)
	// PartnerSelfPhone: HY_BASEURL + "/bizUser/getPartnerPhone",

  //经营报告
 //  PartnerRepData: HY_BASEURL + '/bizPartner/my/info',
 //  //今日收益
 //  partnerTodayData: HY_BASEURL + '/bizAccountRecord/query/day/income',
 //  //合伙人邀请厂家列表
 //  inviteFactoryData: HY_BASEURL + '/bizEnterprise/query/current/user/inv/EN',
 //  //合伙人邀请的合伙人
 //  invitePartnerData: HY_BASEURL + '/bizPartner/query/current/invBP',
 //  ////进出帐记录查询，列表
 //  accountListData: HY_BASEURL + '/bizAccountRecord/query/inOutcome/record',
 //  //信用保证金，列表
 //  creditorDepositListData: HY_BASEURL + '/bizAccountRecord/query/ma/income',

	// //获得厂家信息
	// enterpriseDetData: HY_BASEURL + "/bizEnterprise/read/detail",

	//子用户管理,分页
	// subUserManaPage: HY_BASEURL + "/bizUserSubUser/read/list",
	// //子用户管理,新增
	// subUserManaAdd: HY_BASEURL + "/bizUserSubUser/addSubUser",
	// //子用户管理,删除
	// subUserManaDel: HY_BASEURL + "/bizUserSubUser/deleteSubUser",
	// //子用户管理,更新
	// subUserManaUpdate: HY_BASEURL + "/bizUserSubUser/updateSubUser",
	// //子用户管理,详情
	// subUserManaDet: HY_BASEURL + "/bizUserSubUser/read/detail",
	// //子用户管理,获取主账号的厂家信息
	// subUserManaGetEnterp: HY_BASEURL + "/bizUserSubUser/queryEnterpriseName",
	// //商家账单列表
	// UserMatchFeePage: HY_BASEURL + "/bizUserMatchingFee/queryMatchingFeeByPage",
	// //商家账单明细列表
	// UserMatchingFeeDetPage: HY_BASEURL + "/userMatchFeeDetail/queryMatchFeeByPage",
	// //商家账单状态操作
	// UserUpdateMatchingFee: HY_BASEURL + "/bizUserMatchingFee/updateMatchingFee",

	//账户管理，账户余额查询
	// UserAccountSurplusMoney: HY_BASEURL + "/bizOnlineTranRecord/query/account",
	// //账户管理，账户信息查询
	// UserAccountMoneyDet: HY_BASEURL + "/bizOnlineAccount/query/account",
	// //账户管理，网银提现
	// UserAccountCashWithdrawal: HY_BASEURL + "/bizOnlineTranRecord/account/cash",
	// //账户管理，短信发送接口
	// UserAccountMessage: HY_BASEURL + "/bizOnlineTranRecord/send/message",
	// //个人提现卡绑定
	// UserAccountBindBank: HY_BASEURL + "/bizOnlineTranRecord/online/u04",
	// //个人提现卡解绑
	// UserAccountUntyingBank: HY_BASEURL + "/bizOnlineTranRecord/online/u05",
	// //账户信息
	// UserAccountDet: HY_BASEURL + "/bizOnlineApply/online/account/confirm",
	// //账户收益记录接口
	// AccRecPayPageList: HY_BASEURL + "/bizOnlineTranRecord/query/profit/record",
	// //账户提现记录接口
	// AccWithdrawPageList: HY_BASEURL + "/bizOnlineTranRecord/query/cash/record",
	//开户
	//开户状态查询
	// BankOpenAccountStatus: HY_BASEURL + "/bizOnlineApply/open/acct/status",
	// //个人开户
	// UserOpenAccount: HY_BASEURL + "/bizOnlineTranRecord/online/u01",
	// //开通个人支付账户
	// UserOpenAccountSucCall: HY_BASEURL + "/bizOnlineSubAccount/create/personal/subAcct",
	// //厂家开户
	// EnterpriseOpenAccount: HY_BASEURL + "/bizOnlineApply/open/enterprise/apply",
	// //查询厂家开户信息
	// EnterpriseOpenAccountDet: HY_BASEURL + "/bizOnlineApply/query/apply/info",
	// //厂家开户，银行列表
	// OpenAccountBankList: HY_BASEURL + "/dic/read/online?type=online_enterprise_suport_cash",
	// //网银支付，在线支付
	// ECurrencyPayment: HY_BASEURL + "/bizOnlineApply/bank/pay",
	// //开户银行列表提现须知
	// OpenAccountBankMsgList: HY_BASEURL + "/bizOnlineAccount/query/bank/periodOfTime",

	//平台账户
	// UserAccountPlatformData: HY_BASEURL + "/bizOnlineAccount/query/hyaccount/info",
	// //平台账户-明细
	// UserAccountPlatformDetList: HY_BASEURL + "/bizAccountRecord/query/pe/page",

	// //获得卖家信息
	// // sellerDetData: HY_BASEURL+"/bizSeller/read/detail",
	// //获得买家信息
	// // buyerDetData:  HY_BASEURL+"/bizBuyer/read/detail",
	// //查询仓库
	// storyDetData: HY_BASEURL + "/bizSellerStore/querySellerStoreById",
	// //仓库模糊查询
	// StoreNameLike: HY_BASEURL + "/bizSellerStore/queryByStoreNameLike",
	// //钢-钢厂模糊查询
	// SubValueLike: HY_BASEURL + "/bizSellerSub/queryBySubValueLike",

	//短信服务
	ServicePhoneCode: HY_BASEURL + '/bizVerificationCode/getValidatingCode',//?phoneNumber=17673632769&codeType=0 //（短信类型 验证码类型 0-注册验证码 1-登录验证码 2-验证手机号 3-修改手机号   必填）
	//公用字典，数据字典{key,value}
	HyCommonConfDic: HY_BASEURL + '/bizCommon/dic/query/type',
	//数据字典 list[]
	HyCommonDicList: HY_BASEURL + '/dic/read/list',
	
	//邮箱验证码 {"email":"","codeType":}
	ServiceEmailCode: HY_BASEURL + '/bizVerificationCode/email/getValidatingCode',
	
	//反馈
	// Feedback: HY_BASEURL + '/bizHomeConsultation/insert',
	// //首页-导航
	// HomeNavUrl: HY_BASEURL + '/bizHomeCommon/query/homeMenu',// (module可为空)
	// //首页-商品类型显示内容查询接口
	// HomeTypeContent: HY_BASEURL + '/bizHomeCommon/query/goodsTypeContext',// (menuId不可为空)
	// //首页，互易大宗
	// HomeGoodsMark: HY_BASEURL + '/bizHomeCommon/query/homeGoodsMark', //(menuRoute:  goodsMark.steel--钢  goodsMark.wood--木  goodsMark.cotton--棉 goodsMark.cotton_yarn--棉纱)
	// //首页，撮合集市
	// HomeMatchMark: HY_BASEURL + '/bizHomeCommon/query/homeMatchMark', //(menuRoute： matchMark.steel--钢  matchMark.wood--木  matchMark.cotton--棉 matchMark.cotton_yarn--棉纱)
	// //首页，求购大厅
	// HomeWantBuy: HY_BASEURL + '/bizHomeCommon/query/homeWantBuy', //(menuRoute：wantBuy.steel--钢  wantBuy.wood--木  wantBuy.cotton--棉 wantBuy.cotton_yarn--棉纱)
	// //首页，咨询中心
	// HomeConsultCenter: HY_BASEURL + '/bizHomeCommon/query/homeConsultCenter',

  //首页，行情资讯，共用移动端
 //  HomeConsultationData: HY_BASEURL + '/app/bizMarketInfoValue/queryConsultation',

	// //首页，帮助中心
	// HomeHelpCenter: HY_BASEURL + '/bizHomeCommon/query/homeHelpCenter',
	// //首页，底部数据查询
	// HomeFooterInfo: HY_BASEURL + '/bizHomeCommon/query/homeFooterInfo',
	// //品牌展示查询
	// HyBrandListInfo: HY_BASEURL + '/bizHomeSysGoodShop/queryPage',
	// //首页，商品分类导航
	// HyClassNavInfo: HY_BASEURL + '/bizHomeCommon/query/category',
	// //推荐商户
	// HyRecommendShopListInfo: HY_BASEURL + '/bizHomeCommon/query/recommended/shop',
	// //推荐商户-更多
	// HyRecommendAllShopList: HY_BASEURL + '/bizHomeCommon/query/recommended/more',

	//产能预售
	//列表
	// HyCapacityDataByPage: HY_BASEURL + '/preSellCapacity/page',
	// //详情
	// HyCapacityDataDetById: HY_BASEURL + '/preSellCapacity/detail',
	// //新增产能预售明细
	// HyCapacityDataDetListAdd: HY_BASEURL + '/preSellCapacityDetail',

	// //互易货的提交
	// Hylogistics: HY_BASEURL + '/bizHomeHylogistics/insert',

	//购物车
	//购物车角标列表
	// HyShopCartSmallList: HY_BASEURL + '/bizMarketCart/getDisplayGoods',
	// //购物车角标列表合计
	// HyShopCartListCount: HY_BASEURL + '/bizMarketCart/getSKUAmount',
	// //购物车，新增
	// ShopCartAdd: HY_BASEURL + '/bizMarketCart/addCart',
	// //购物车，修改
	// ShopCartUpdate: HY_BASEURL + '/bizMarketCart/updateCart',
	// //购物车，删除
	// ShopCartDel: HY_BASEURL + '/bizMarketCart/deleteCart',
	// //购物车，查询
	// ShopCartQuery: HY_BASEURL + '/bizMarketCart/queryCart',
	// //购物车，批量下单
	// // ShopCartAddOrder: HY_BASEURL+'/bizMarketOrder/batchAddOrders',
	// ShopCartAddOrder: HY_BASEURL + '/bizMarketOrder/addOrders',
	// //购物车，订单列表
	// ShopCartOrderQuery: HY_BASEURL + '/bizMarketCart/qryCartsByIds',
	// //根据商品编号查询购物车商品
	// ShopCartQueryGoodsByNos: HY_BASEURL + '/bizMarketCart/queryCartByGoodsNo',

	//民生 购物车
	//购物车角标列表
	// HyLShopCartSmallList: HY_BASEURL + '/bizLivelihoodCart/queryDisplayCart',
	// //统计购物车商品数量接口，购物车角标列表合计
	// HyLShopCartListCount: HY_BASEURL + '/bizLivelihoodCart/getGoodsAmount',
	// //浏览购物车接口
	// LShopCartQuery: HY_BASEURL + '/bizLivelihoodCart/queryLivelihoodCartByPage',
	// //修改购物车商品数量接口
	// LShopCartUpdate: HY_BASEURL + '/bizLivelihoodCart/updateLivelihoodCart',
	// //加入购物车接口
	// LShopCartAdd: HY_BASEURL + '/bizLivelihoodCart/addLivelihoodCart',
	// //批量删除购物车商品接口
	// LShopCartDel: HY_BASEURL + '/bizLivelihoodCart/updateLivelihoodCartByDelete',
	// //购物车，批量下单
	// LShopCartAddOrder: HY_BASEURL + '/bizLivelihoodOrder/addOrders',
 //  // 购物车加购接口，/app/bizLivelihoodCart/addLivelihoodCart/v2
 //  HyAddShopcar: HY_BASEURL + "/bizLivelihoodCart/addLivelihoodCart/v2",
	// //购物车，订单列表
	// LShopCartOrderQuery: HY_BASEURL + '/bizLivelihoodCart/queryLivelihoodCartByIds',

 //  //新，购物车角标下拉
 //  HyLShopCartSmallListNew: HY_BASEURL + '/bizLivelihoodCart/query/page/my/cart/v2',

  //新购物车
  // LShopCartQueryNew: HY_BASEURL + '/bizLivelihoodCart/query/page/my/cart/v2',
  // //修改购物车商品数量接口
  // LShopCartUpdateNew: HY_BASEURL + '/bizLivelihoodCart/cart/attr/add',
	//根据商品编号查询购物车商品
	// LShopCartQueryGoodsByNos: HY_BASEURL+'/bizMarketCart/queryCartByGoodsNo',
	//民生订单，支付宝支付
	// HyAliWebPay: HY_BASEURL + '/hyLife/aliWebPay',
	// //民生订单，微信支付 form提交
	// HyWxWebPay: HY_BASEURL + '/hyLife/wxWebPay',
	// //民生订单，微信支付 code码
	// HyWxWebPayCode: HY_BASEURL + '/hyLife/get/wxWebPay',
	// //民生订单，网银支付 code码
	// HyEBankPayCode: HY_BASEURL + '/bizOnlineApply/hyLife/fast/pay/206',
	// //民生订单，网银支付，厂家网银
	// HyEBankCompanyPayCode: HY_BASEURL + '/bizOnlineApply/hyLife/pay/204/html',

	//民生，商品广告
	// HyLivelihoodAdv: HY_BASEURL + '/bizLivelihoodAdvertising/read/detail',

	// //民生，订单列表
	// HyLOrderList: HY_BASEURL + '/bizLivelihoodOrder/queryOrderByPage',
	// //民生，订单详情
	// HyLOrderById: HY_BASEURL + '/bizLivelihoodOrder/queryOrderById',

	// //民生，订单状态统计
	// LOrderPageCount: HY_BASEURL + '/bizLivelihoodOrder/statisticalProcessQuantity',
	// //民生-设置发货
	// LOrderProcessDeliveryGoods: HY_BASEURL + '/bizLivelihoodOrder/updateOrderByShipped',
	// //民生-取消订单
	// LOrderProcessCancelOrder: HY_BASEURL + '/bizLivelihoodOrder/updateOrderByCancel',
	// //民生-收货确认,订单
	// LOrderProcessReceivedGoods: HY_BASEURL + '/bizLivelihoodOrder/updateOrderByReceived',
 //  //改为自提发货订单
 //  LOrderUpdateSelfShipments: HY_BASEURL + '/bizLivelihoodOrder/update/Self/raising',
 //  //修改订单备注
 //  LOrderModifyRemark: HY_BASEURL + '/bizLivelihoodOrder/update/remark',

 //  //民生，订单物流信息./app/bizLogisticsInfo/query/
 //  HyLogisticsDet: HY_BASEURL + '/bizLogisticsInfo/query',
 //  //民生，查询订单地址信息
 //  HyOrderAddressDet: HY_BASEURL + '/bizLivelihoodAddressSnapshot/query',
 //  //民生，修改地址信息/app/bizLivelihoodAddressSnapshot/update/address
 //  HyOrderAddressUpdate: HY_BASEURL + '/bizLivelihoodAddressSnapshot/update/address',

	// //民生商品，查询属性规格
	// HyGoodsDetSelAttr: HY_BASEURL + '/bizShopGoodsDetail/queryBizShopGoodsDetail',

	//订单，合同列表
	// OrderContractsPageList: HY_BASEURL + '/bizContract/qryContractByPage',
	// //订单，合同批量列表
	// OrderContractsList: HY_BASEURL + '/bizContract/queryContractByContractId',
	// //订单，合同浏览
	// OrderViewContracts: HY_BASEURL + '/bizContract/viewContract',
	// //订单，合同签章
	// // OrderContractsSignature: HY_BASEURL+'/bizContract/automaticSignature',
	// OrderContractsSignature: HY_BASEURL + '/bizContract/updateContractBySign',

	// //生成合同，合同场景选择
	// AddOrderContracts: HY_BASEURL + '/bizContract/addContract',

	// //订单，合同下载
	// OrderContractsDownLoad: HY_BASEURL + '/bizContract/downloadContract',

	// //订单统计，大宗，民生
	// OrderCountAll: HY_BASEURL + '/bizLivelihoodOrder/countOrderAmount',

	// //搜索时棉批号查询
	// CottonBatchSerCount: HY_BASEURL + '/bizHomeCommon/cottonSearch/count',
	// //棉批号详细信息查询，中纤局棉信息
	// CottonBatchNoSer: HY_BASEURL + '/bizCcqscCotton/query/batchNo',
	// //棉批号明细信息查询，中纤局棉信息明细查询
	// CottonBatchNoDetList: HY_BASEURL + '/bizCcqscCottonRecord/query/batchNo',
	// //新，棉批号查询--大渊博棉花信息查询
	// CottonBatchNoSer_new: HY_BASEURL + '/bizCcqscCotton/query/dybBatchNo',
	// //升贴水查询
	// CottonBatchNoSer_STS: HY_BASEURL + '/bizCcqscCotton/query/shengtieshui',
	// //升贴水查询
	// CottonBatchNoSer_STS_standard: HY_BASEURL + '/bizCcqscCotton/query/jizhunjia',
	//卖家，钢。互易大宗、撮合集市。
	// SellerSteel : HY_BASEURL+"/bizShopSteel/read/list",
	//卖家，木。互易大宗、撮合集市。
	// SellerWood : HY_BASEURL+"/bizShopWood/read/list",
	//卖家，棉。互易大宗、撮合集市。
	// SellerCotton : HY_BASEURL+"/bizShopCotton/read/list",
	//卖家，棉纱。互易大宗、撮合集市。
	// SellerCottonYarn : HY_BASEURL+"/bizShopCottonYarn/read/list",

	//卖家，产品，Goods，分页
	// GoodsByPage: HY_BASEURL + '/bizGoods/queryGoodsByPage',
	// GoodsByCount: HY_BASEURL + '/bizGoods/read/sellerCount',
	// //商品列表筛选项
	// GoodsReleaseTem: HY_BASEURL + '/bizSellerAttribute/read/list',
	// //卖家商品，商品发布页根据属性配置动态生成
	// GoodsAttrListTem: HY_BASEURL + '/bizSellerAttribute/read/list',

 //  //价格最新资讯
 //  priceInfoList: HY_BASEURL + "/app/bizShopGoodsPriceShow/query/price/page",

	//商品类目管理
  //树形类目
  HyCommodityCateTreeList: HY_BASEURL+"/bizShopGoods/shopGoodsSubject/tree",
	//查询
	HyCommodityCateList: HY_BASEURL + "/bizShopGoods/shopGoodsSubject/queryByParams",
	//多类目商品属性
	HyCommodityCateAttrsList: HY_BASEURL + "/bizShopGoods/shopGoodsAttribute/queryByPageAttribute",
	//多类目商品
	//新增
	CateGoodsAdd: HY_BASEURL + '/bizShopGoods/shopGoods/insert',
	//修改
	CateGoodsUpdate: HY_BASEURL + '/bizShopGoods/shopGoods/update',
	//删除
	CateGoodsDel: HY_BASEURL + '/bizShopGoods/shopGoods/delete',
	//查询
	CateGoodsPageList: HY_BASEURL + '/bizShopGoods/shopGoods/queryByPage',
	//查询详情
	CateGoodsDet: HY_BASEURL + '/bizShopGoods/shopGoods/queryGoodsDetailByGoodsNo',
	//上、下架操作
	// CateGoodsUpdateState: HY_BASEURL + '/bizShopGoods/shopGoods/update/state',

  //商品推荐-商品编号-按照类目
  // HyGoodSimilar: HY_BASEURL + "/app/v2/bizShopGoods/query/similar/goods/page",


	//导入
	// GoodsInsertBathExcel: HY_BASEURL + '/bizGoods/insertBathExcel',
	// //卖家，新增，钢
	// SellerAddSteel: HY_BASEURL + "/bizShopSteel/insert",
	// //卖家，新增，木
	// SellerAddWood: HY_BASEURL + "/bizShopWood/insert",
	// //卖家，新增，棉
	// SellerAddCotton: HY_BASEURL + "/bizShopCotton/insert",
	// //卖家，修改，钢
	// SellerUpdateSteel: HY_BASEURL + "/bizShopSteel/update",
	// //卖家，修改，木
	// SellerUpdateWood: HY_BASEURL + "/bizShopWood/update",
	// //卖家，修改，棉
	// SellerUpdateCotton: HY_BASEURL + "/bizShopCotton/update",

	//卖家，新增，棉纱
	// SellerAddCottonYarn: HY_BASEURL + "/bizShopCottonYarn/insert",
	// //卖家，修改，棉纱
	// SellerUpdateCottonYarn: HY_BASEURL + "/bizShopCottonYarn/update",

	// //卖家，新增，车
	// SellerAddCar: HY_BASEURL + "/bizShopCar/insert",
	// //卖家，修改，车
	// SellerUpdateCar: HY_BASEURL + "/bizShopCar/update",

	// //卖家，新增，酒
	// SellerAddLiquor: HY_BASEURL + "/bizShopLiquor/insert",
	// //卖家，修改，酒
	// SellerUpdateLiquor: HY_BASEURL + "/bizShopLiquor/update",

	// //卖家，新增，茶
	// SellerAddTea: HY_BASEURL + "/bizShopTea/insert",
	// //卖家，修改，茶
	// SellerUpdateTea: HY_BASEURL + "/bizShopTea/update",

	//卖家，批量商品有效期修改
	// SellerUpdateGoodsIndate: HY_BASEURL + "/bizGoods/updateGoodsIndate",

	// //卖家，上架状态修改
	// SellerUpdateGoodsStatus: HY_BASEURL + "/bizGoods/updateGoodsStatus",
	// //卖家，批量价格修改
	// SellerUpdateGoodsPrice: HY_BASEURL + "/bizGoods/updateGoodsPrice",
	// //卖家，批量修改
	// SellerUpdateGoodsList: HY_BASEURL + "/bizGoods/updateGoodsBatch",
	// //卖家，批量修改图片
	// SellerUpdateGoodsImgList: HY_BASEURL + "/bizGoods/updateGoodsImage",
	// //卖家，上架状态修改
	// SellerDeleteGoodsData: HY_BASEURL + "/bizGoods/updateGoodsEnable",
	// //卖家商品列表
	// SellerGoods: HY_BASEURL + "/bizGoods/read/list",
	// //卖家商品详情
	// SellerGoodsDet: HY_BASEURL + "/bizGoods/read/goods",
	// //卖家，新增，报价单
	// SellerQuoteAdd: HY_BASEURL + "/bizSellerOffer/insertOffer",
	// //卖家，修改，报价单
	// SellerQuoteUpdate: HY_BASEURL + "/bizSellerOffer/updateOffer",
	// //卖家，报价单，分页
	// SellerQuoteList: HY_BASEURL + "/bizSellerOffer/read/list",
	// //根据询价单id查报价单
	// QuoteListByNoList: HY_BASEURL + "/bizSellerOffer/queryByAskBuyNoList",

	//查询商品sku，加入购物车前查询
	// queryGoodsAttrSku: HY_BASEURL + "/bizShopGoodsDetail/queryByPurchased",

	//查询提成
	// queryPushMoney: HY_BASEURL + "/bizOnlineStlCalc/clac/percentTage",

	//卖家，集采分页查询，(用户)
	// GroupBuyAllPageList: HY_BASEURL + "/bizCrowdProject/read/list",
	// //卖家，集采分页查询，(厂家)
	// GroupBuyPageList: HY_BASEURL + "/bizCrowdProject/queryByEnterprise",
	// //卖家，集采,新增
	// GroupBuyAdd: HY_BASEURL + "/bizCrowdProject/insertEntity",
	// //卖家，集采,更新
	// GroupBuyUpdate: HY_BASEURL + "/bizCrowdProject/updateEntity",
	// //卖家，集采,删除
	// GroupBuyDel: HY_BASEURL + "/bizCrowdProject/deleteEntity",
	// //卖家，集采,修改项目状态
	// GroupBuyUpdateStatus: HY_BASEURL + "/bizCrowdProject/updateByProjectStatus",
	// //填充预购单数量
	// FillPreorder: HY_BASEURL + "/bizCrowdDetail/insertBySeller",
	// //项目调价
	// ProjectPricing: HY_BASEURL + "/bizCrowdProject/updateByAdjustAmount",
	// //项目进度，项目里程碑，分页列表
	// ProMilestonesPageList: HY_BASEURL + "/bizCrowdPlan/read/list",
	// //新增里程碑
	// ProMilestonesAdd: HY_BASEURL + "/bizCrowdPlan/insertEntity",

	//集采预购列表（userId和enterpriseId不能同时为空）
	// PreorderPageList: HY_BASEURL + "/bizCrowdDetail/queryByUser",

	// //集采预购列表（projectId：项目ID必传）
	// PreorderListByProjectId: HY_BASEURL + "/bizCrowdDetail/read/list",

	// //发布厂家或预购用户取消预购信息
	// updatePreorderStatus: HY_BASEURL + "/bizCrowdProject/updateByCancelDetail",

	// //预购单。卖家。返现
	// returnCashData: HY_BASEURL + "/bizCrowdDetail/updateByAdvanceStatus",

	// //计算预购费用信息
	// queryPreCostData: HY_BASEURL + "/bizCrowdDetail/queryAmountInfo",
	// //参与集采项目(买家新增预购明细)
	// JoinGroupBuyAdd: HY_BASEURL + "/bizCrowdDetail/insertEntity",

	//集采支付，
	//支付宝支付
	// HyPreOrderAliWebPay: HY_BASEURL + '/collect/aliWebPay',
	// //微信支付 form提交
	// // HyPreOrderWxWebPay: HY_BASEURL+'/hyLife/wxWebPay',
	// //微信支付 code码
	// // HyPreOrderWxWebPayCode: HY_BASEURL+'/hyLife/get/wxWebPay',
	// //网银支付 code码
	// HyPreOrderEBankPayCode: HY_BASEURL + '/bizOnlineApply/collect/pay/206/html',
	// //网银支付，厂家网银
	// HyPreOrderEBankCompanyPayCode: HY_BASEURL + '/bizOnlineApply/collect/pay/204/html',

	// //民生商品，立即购买
	// HyBuyNowData: HY_BASEURL + '/bizLivelihoodOrder/addSingleOrder',

 //  //民生商品购买，新
 //  HyPlaceOrder: HY_BASEURL + "/v2/bizLivelihoodOrder/add/order",
 //  // 订单确认接口,/app/v2/bizLivelihoodOrder/confirm/order，根据sku和数量
 //  HyOrderConfirm: HY_BASEURL + "/v2/bizLivelihoodOrder/confirm/order",

 //  //订单确认接口,根据 id
 //  HyOrderConfirmByIds: HY_BASEURL + "/bizLivelihoodCart/cart/comein/confirm/order",

 //  //民生订单--根据订单编号查询 返回列表
 //  HyLOrderListByOrderSn: HY_BASEURL + '/bizLivelihoodOrder/queryOrderByOderSn',

	// //商户信息，商户部分,店铺包含注册用户的信息
	// EnterpriseUserDet: HY_BASEURL + "/bizShopHomepage/read/homepageDetail",
	// 店铺信息修改
	// ShopUpdate: HY_BASEURL + "/bizShop/updateShop",
	// //店铺，明细
	// ShopDataDet: HY_BASEURL + "/bizShop/read/detail",
	// // 商户主页详情1
	// ShopHomePageDet: HY_BASEURL + "/bizShopHomepage/read/detail",
	//  商户主页,头2
	// ShopHomePageHeadDet: HY_BASEURL+"/bizShopHomepage/read/shopHomepageDetail",
	//  商户主页,头3
	// ShopHomePageSideDet: HY_BASEURL+"/bizShopHomepage/read/shopHomepageOneDetail",
	// 商户主页新增
	// ShopHomePageAdd: HY_BASEURL + "/bizShopHomepage/add/shopHomepage",
	// // 商户主页修改
	// ShopHomePageUpdate: HY_BASEURL + "/bizShopHomepage/update/shopHomepage",


	//数据字典。互易大宗，撮合集市
	SpotDataDictionary: HY_BASEURL + "/bizMarketSpecValue/read/specvalues",
	//数据字典，获得单个
	DictionaryDataSpec: HY_BASEURL + "/bizMarketSpecValue/read/querySpecValuesBySpId",
	//省份数据
	ProvinceListData: HY_BASEURL + '/sysProvince/queryAllProvince',
	//城市数据
	ProvinceCityData: HY_BASEURL + '/sysCity/queryCityByProvinceCode',
	//区县数据
	CityCountyData: HY_BASEURL + '/sysArea/queryAreaByCityCode',
	//仓库数据
	CityStoryData: HY_BASEURL + '/bizSellerStore/querySellerStoreByPlace',
	//新增仓库
	AddStoryData: HY_BASEURL + '/bizSellerStore/insert',

	//汇付天下，省份数据
	// HftxProvinceListData: HY_BASEURL + '/dic/read/online?type=online_prov',
	//汇付天下，区域数据
	// HftxProvinceCityData: HY_BASEURL + '/dic/read/online?type=online_area&parentCode=',

	//买家，新增，询价单
	// BuyerInquiryAdd: HY_BASEURL + "/bizBuyInquiry/insert",
	// //买家，分页查询，询价单,求购大厅
	// BuyerInquiryList: HY_BASEURL + "/bizBuyInquiry/read/list",

	// //求购列表，求购大厅 2.0
	// HyInquiryPageList: HY_BASEURL + "/bizEnquiry/read/list",
	// //新增求购单，2.0
	// HyInquiryAdd: HY_BASEURL + "/bizEnquiry/insertEntity",
	// //更新求购单状态
	// HyInquiryUpdateStatus: HY_BASEURL + "/bizEnquiry/updateEnquiryStatus",
	// //新求购单详情
	// HyInquiryData: HY_BASEURL + "/bizEnquiry/queryByList",
	// //报价列表 2.0
	// HyQuotePageList: HY_BASEURL + "/bizQuote/read/list",
	// //求购单报价新增 2.0
	// HyQuoteAdd: HY_BASEURL + "/bizQuote/insert",

	//新版发布-求购
	//添加
	// HyInquiryAddNew: HY_BASEURL + "/bizEnquiryV2/publish",
	// //列表
	// // HyInquiryPageListNew: HY_BASEURL + "/bizEnquiryV2/query/page",
 //  HyInquiryPageListNew: HY_BASEURL + "/bizEnquiryV2/query/publish/page",
 //  //详情/bizEnquiryV2/query/{id}
 //  HyInquiryDetNew: HY_BASEURL + "/bizEnquiryV2/query",



	// //买家，查询提货单详情
	// BuyerBillLadingList: HY_BASEURL + '/bizBuyPick/read/detail',
	// //买家，新增提货单信息
	// BuyerBillLadingInsert: HY_BASEURL + '/bizBuyPick/insert',
	// //买家，修改提货单信息
	// BuyerBillLadingUpdate: HY_BASEURL + '/bizBuyPick/update',

	//买家，新增，收货地址
	// BuyerAddArress: HY_BASEURL + "/bizBuyAddress/insert",
	// //买家，分页查询，收货地址
	// BuyerArressList: HY_BASEURL + "/bizBuyAddress/read/list",
	// //买家，分页，收货地址删除
	// BuyerArressDel: HY_BASEURL + "/bizBuyAddress/delete",
	// //买家，分页，收货地址更新
	// BuyerArressUpdate: HY_BASEURL + "/bizBuyAddress/update",
	// //买家，收货地址单个查询，根据会员ID
	// BuyerArressDefault: HY_BASEURL + "/bizBuyAddress/read/default",

	// //买家，新增，发票地址
	// BuyerAddReceipt: HY_BASEURL + "/bizBuyInvoice/insert",
	// //买家，分页查询，发票地址
	// BuyerReceiptList: HY_BASEURL + "/bizBuyInvoice/read/list",
	// //买家，分页，发票地址删除
	// BuyerReceiptDel: HY_BASEURL + "/bizBuyInvoice/delete",
	// //买家，分页，发票地址更新
	// BuyerReceiptUpdate: HY_BASEURL + "/bizBuyInvoice/update",
	// //买家，收货地址单个查询，根据会员ID
	// BuyerReceiptDefault: HY_BASEURL + "/bizBuyInvoice/read/default",

	// //卖方，厂家服务费发票地址
	// SellerFeeReceipt: HY_BASEURL + "/bizBuyInvoice/queryByEnterprise",

	//合伙人，卖家管理分页-我的推荐厂家
	// PartnerSellerManage: HY_BASEURL + "/bizShop/read/list",
	// //合伙人，卖家资料查询
	// PartnerSellerDet: HY_BASEURL + "/bizSeller/read/detail",
	// //合伙人，卖家订单查询查询
	// PartnerSellerOrder: HY_BASEURL + "/bizMarketOrder/queryOrder",
	// //合伙人资料
	// PartnerInfoDet: HY_BASEURL + "/bizPartner/read/detail",
	// //合伙人，发放证书，查询
	// HyPartnerCertiGeneQuery: HY_BASEURL + "/bizPartnerCertificate/read/detail",
	// //合伙人账单列表
	// PartnerMatchFeePage: HY_BASEURL + "/bizPartnerMatchingFee/read/list",
	// //合伙人账单明细列表
	// PartnerMatchingFeeDetPage: HY_BASEURL + "/bizPartnerMatchingFeeDetail/read/list",
	// //合伙人账单状态
	// PartnerMatchingFeeModifyPage: HY_BASEURL + "/bizPartnerMatchingFee/update",
	// //合伙人，-我的推荐合伙人
	// PartnerRecPartnerManage: HY_BASEURL + "/bizPartner/read/queryPartnerList",
	// //合伙人类型查询
	// PartnerTypeList: HY_BASEURL + "/sysAttribute/showAttributeList",
 //  //合伙人类型查询
 //  PartnerTypeListNew: HY_BASEURL + "/dic/read/list",

	//订单，汇总，统计各个状态订单数
	// OrderCountStatu: HY_BASEURL + "/bizMarketOrder/countOrderByState",
	// //订单，汇总，统计订单销售情况
	// OrderCount: HY_BASEURL + "/bizMarketOrder/countOrderByDate",
	// //订单，新增
	// OrderAdd: HY_BASEURL + "/bizMarketOrder/addOrder",
	// //订单，统计各流程订单数
	// OrderPageCount: HY_BASEURL + "/bizMarketOrder/getOrderProcessAmount",
	// //订单，分页查询
	// OrderPageList: HY_BASEURL + "/bizMarketOrder/queryOrder",
	// //订单，修改
	// OrderModify: HY_BASEURL + "/bizMarketOrder/updateOrder",
	// //订单-合同上传
	// OrderProcessUploadContract: HY_BASEURL + "/bizMarketOrder/uploadContract",
	// //订单-订单确认
	// OrderProcessConfirmOrder: HY_BASEURL + "/bizMarketOrder/confirmOrder",
	// //订单-上传付款凭证
	// OrderProcessUploadPaymentVoucher: HY_BASEURL + "/bizMarketOrder/uploadPaymentVoucher",
	// //订单-审核付款凭证
	// OrderProcessAuditPayment: HY_BASEURL + "/bizMarketOrder/auditPayment",
	// //订单-发货操作
	// OrderProcessDeliveryGoods: HY_BASEURL + "/bizMarketOrder/deliveryGoods",
	// //订单-收货操作
	// OrderProcessReceivedGoods: HY_BASEURL + "/bizMarketOrder/receivedGoods",
	// //订单-取消操作
	// OrderProcessCancelOrder: HY_BASEURL + "/bizMarketOrder/cancelOrder",

	//根据资讯分类分页显示
	// QueryInfos:HY_BASEURL+"/bizMarketInfo/queryInfos",
	//资讯中心
	//分页数据
	// InformationPageList: HY_BASEURL + "/bizMarketInfo/queryInfos",
	// //分页数据
	// InformationPageDet: HY_BASEURL + "/bizMarketInfoValue/queryNews",
	// //行情报价
	// InformationQuotation: HY_BASEURL + "/bizMarketInfoValue/quotation",
	// //详情+翻页
	// InformationQuotationDetailPage: HY_BASEURL + "/bizMarketInfoValue/contentPrice",
	// //行情资讯
	// InformationIndustry: HY_BASEURL + "/bizMarketInfoValue/queryConsultation",
	// //相关文章
	// InformationIndustryAbout: HY_BASEURL + "/bizMarketInfoValue/lastNews",

	// //资源单
	// //分页数据
	// ResourceListPageList: HY_BASEURL + "/bizBuyResource/read/list",
	// //新增资源单信息
	// ResourceAdd: HY_BASEURL + "/bizBuyResource/insertEntity",
	// //修改资源单信息
	// ResourceUpdate: HY_BASEURL + "/bizBuyResource/updateEntity",
	// //修改资源单文件下载次数
	// ResourceCountAdd: HY_BASEURL + "/bizBuyResource/updateByDownload",
	// //删除资源单信息（逻辑删除）
	// ResourceDel: HY_BASEURL + "/bizBuyResource/deleteEntity",

	//app 店铺详情
	// AppShopDet: HY_BASEURL+"/app/bizShop/read/detail",
	// app 厂家信息
	// AppEnterpriseDet: HY_BASEURL+"/app/bizEnterprise/read/detail",
	//商户信息
	// AppShopEnterpriseDet: HY_BASEURL + "/app/bizShopHomepage/read/detailByEnterpriseId",

	// 查询提货单详情
	// BillCodeSer: HY_BASEURL + "/app/bizBuyPick/getPickByStore",
	// 确认提示
	// SureBillCode: HY_BASEURL + "/app/bizBuyPick/updatePickByStore",
	// 确认提示2
	// SureBillCodeNew: HY_BASEURL + "/app/bizBuyPick/updatePickByGetStore",

  //字典集合，数据字典，多类型查询
  HyDicQueryList: HY_BASEURL+"/dic/query/list",
  //我的仓库
  //我的SKU-分页查询
  // /warehouse/bizWhGoodsSku/query/my/page
  WhMySkuPageList:  HY_BASEURL + "/warehouse/bizWhGoodsSku/query/my/page",
  //我的SKU-删除
  // /warehouse/bizWhGoodsSku/delete/{id}
  WhMySkuDel:  HY_BASEURL + "/warehouse/bizWhGoodsSku/delete",
  //我的SKU-新增编辑
  // /warehouse/bizWhGoodsSku/updateInsert
  WhMySkuAddUpdate:  HY_BASEURL + "/warehouse/bizWhGoodsSku/updateInsert",

  // 我的仓库费-明细分页
  // /warehouse/bizWhFeeRecord/query/my/page
  WhMyFeePageList:  HY_BASEURL + "/warehouse/bizWhFeeRecord/query/my/page",
	
	//仓库费用统计
	WhCostStatisticsPageList: HY_BASEURL +"/warehouse/bizWhFeeRecord/statistics/query/page",

  //我的信息-新增仓库用户信息
  // /warehouse/bizWhCustomer/edit/update
  WhMyCustomerUpdate:  HY_BASEURL + "/warehouse/bizWhCustomer/edit/update",

  // 我的信息-获取仓库用户信息
  // /warehouse/bizWhCustomer/user/whinfo
  WhMyCustomerInfo:  HY_BASEURL + "/warehouse/bizWhCustomer/user/whinfo",

  // 我的出货单-出货单编辑更新
  // 参照 下架管理-新增编辑-同下架管理
  WhMyDownShelfAddUpdate:  HY_BASEURL + "/warehouse/bizWhDownShelf/edit/update",

  //我的出货单-分页查询
  // /warehouse/bizWhDownShelf/query/my/page
  WhMyDownShelfPageList:  HY_BASEURL + "/warehouse/bizWhDownShelf/query/my/page",

  //我的出货单-新增-选择商品-同下架管理
  // /warehouse/bizWhShelves/query/my/page
  WhMyDownShelfAddSelList:  HY_BASEURL + "/warehouse/bizWhShelves/query/my/page",

  // 我的出货单-提交指令//
  // /warehouse/bizWhDownShelf/commit/command/{id}
  WhMyDownShelfCommand:  HY_BASEURL + "/warehouse/bizWhDownShelf/commit/command",

  //我的装箱单-分页查询
  // /warehouse/bizWhGoodsPacking/query/my/page
  WhMyGoodsPackingPageList:  HY_BASEURL + "/warehouse/bizWhGoodsPacking/query/my/page",

  // 我的装箱单-新增编辑
  // /warehouse/bizWhGoodsPacking/updateInsert
  WhMyGoodsPackingAddUpdate:  HY_BASEURL + "/warehouse/bizWhGoodsPacking/updateInsert",

  //我的装箱单-发货
  // /warehouse/bizWhGoodsPacking/to/{id}
  WhMyGoodsPackingShipments:  HY_BASEURL + "/warehouse/bizWhGoodsPacking/to",
	
	//我的装箱单-撤销发货
	// /wh/bizWhMyGoodsPacking/cancel/command
	WhMyGoodsPackingCancel:  HY_BASEURL + "/wh/bizWhMyGoodsPacking/cancel/command",

  //我的装箱单-明细
  // /warehouse/bizWhGoodsPacking/to/{id}
  WhMyGoodsPackingDetList:  HY_BASEURL + "/warehouse/bizWhGoodsClass/query/my/page",

  //仓配-2版
  //sku信息-分页
  WhMyGoodsSkuPageList:  HY_BASEURL + "/wh/bizWhMyGoodsSku/query/my/page",
  //sku信息-新增编辑
  WhMyGoodsSkuUpdate:  HY_BASEURL + "/wh/bizWhMyGoodsSku/updateInsert",
  //sku信息-删除/wh/bizWhMyGoodsSku/delete/{id}
  WhMyGoodsSkuDel:  HY_BASEURL + "/wh/bizWhMyGoodsSku/delete",
  //sku信息-excel上传读取
  WhMyGoodsSkuExUpload:  HY_BASEURL + "/wh/bizWhMyGoodsSku/batch/add/excel",
  //sku信息-Excel读取提交
  WhMyGoodsSkuExSubmit:  HY_BASEURL + "/wh/bizWhMyGoodsSku/batch/add/list",
	
	//批量删除
	// /wh/bizWhMyGoodsSku/batch/delete/{userId}    {"ids":[]}
	WhMyGoodsSkuBatchDel:  HY_BASEURL + "/wh/bizWhMyGoodsSku/batch/delete",
	
  //sku信息-SKU 流水分页
  WhMyGoodsSkuRecPageList:  HY_BASEURL + "/wh/bizWhMySkuStockSeq/query/my/page",
  //sku信息-SKU库存分页查询
  WhMyGoodsSkuStockPageList:  HY_BASEURL + "/wh/bizWhMySkuStock/query/my/page",
	//库位查询 /wh/bizWhMySkuStock/query/sku/place?goodsSku=XXXXXXXX
	WhMyGoodsSkuPlace:  HY_BASEURL + "/wh/bizWhMySkuStock/query/sku/place",
	
	//库存锁定-所有 /wh/bizWhMySendLockStock/query/page
	WhMySendStockLockPageList: HY_BASEURL+"/wh/bizWhMySendLockStock/query/page",
	
	//库存明细
	// /wh/bizWhMySkuStock/query/stock/detail/page   库存明细列表
	WhStockDetPageList: HY_BASEURL+"/wh/bizWhMySkuStock/query/stock/detail/page",
	
	//sku-拆分 /wh/bizWhSkuBreakUp/query/page
	WhSkuSplitPageList:  HY_BASEURL + "/wh/bizWhSkuBreakUp/query/page",
	//sku-拆分-新增 /wh/bizWhSkuBreakUp/update
	WhSkuSplitAdd:  HY_BASEURL + "/wh/bizWhSkuBreakUp/update",
	//sku-拆分-删除 /wh/bizWhSkuBreakUp/delete/{id}
	WhSkuSplitDel:  HY_BASEURL + "/wh/bizWhSkuBreakUp/delete",
	//sku-拆分-取消 /wh/bizWhSkuBreakUp/cancel/{id}
	WhSkuSplitCancel:  HY_BASEURL + "/wh/bizWhSkuBreakUp/cancel",
	//sku-拆分-明细 /wh/bizWhSkuBreakUpRecord/query/{breakId}
	WhSkuSplitDetList:  HY_BASEURL + "/wh/bizWhSkuBreakUpRecord/query",
	//sku-拆分-提交 /wh/bizWhSkuBreakUp/commit
	WhSkuSplitSubmit:  HY_BASEURL + "/wh/bizWhSkuBreakUp/commit",

  //我的装箱单-分页
  WhPackingListPageList:  HY_BASEURL + "/wh/bizWhMyGoodsPacking/query/my/page",
  //我的装箱单-新增编辑 /wh/bizWhMyGoodsPacking/updateInsert
  WhPackingListUpdate:  HY_BASEURL + "/wh/bizWhMyGoodsPacking/updateInsert",
  //我的装箱单-删除/wh/bizWhMyGoodsPacking/delete/{id}
  WhPackingListDel:  HY_BASEURL + "/wh/bizWhMyGoodsPacking/delete",
  //我的装箱单-excel上传读取
  WhPackingListExUpload:  HY_BASEURL + "/wh/bizWhMyGoodsPacking/reader/excel",
  //我的装箱单-Excel读取提交
  WhPackingListExSubmit:  HY_BASEURL + "/wh/bizWhMyGoodsPacking/save/excel",
  //我的装箱单-明细分页查询
  WhPackingListDetPageList:  HY_BASEURL + "/wh/bizWhMyGoodsPackingRecord/query/my/page",
  //我的装箱单-发货/wh/bizWhMyGoodsPacking/to/{id}
  WhPackingListShipments:  HY_BASEURL + "/wh/bizWhMyGoodsPacking/to",
	//入库单-批量
	// WhPackingListBatchUpdate:  HY_BASEURL + "/wh/bizWhMyGoodsPacking/save/excel",
	//入库单=提交 /wh/bizWhMyGoodsPacking/commit    {"ids":[]}
	WhPackingListCommit:  HY_BASEURL + "/wh/bizWhMyGoodsPacking/commit",
	//入库单-编辑查询 /wh/bizWhMyGoodsPacking/query/{packId} GET 
	WhPackingListQueryId:  HY_BASEURL + "/wh/bizWhMyGoodsPacking/query",
	//入库单-编辑保存 /wh/bizWhMyGoodsPacking/updateEdit
	WhPackingListEditSave:  HY_BASEURL + "/wh/bizWhMyGoodsPacking/updateEdit",

  //我的发货单-分页
  WhShipmentsListPageList:  HY_BASEURL + "/wh/bizWhMySendGoods/query/my/page",
  //我的发货单-新增编辑 
  WhShipmentsListUpdate:  HY_BASEURL + "/wh/bizWhMySendGoods/updateInsert",
  //我的发货单-删除 /wh/bizWhMySendGoods/delete/{id}
  WhShipmentsListDel:  HY_BASEURL + "/wh/bizWhMySendGoods/delete",
  //我的发货单-SKU明细导入
  WhShipmentsListExUpload:  HY_BASEURL + "/wh/bizWhMySendGoodsRecord/batch/add/excel",
  //我的发货单-Excel读取提交
  // WhShipmentsListExSubmit:  HY_BASEURL + "/wh/bizWhMyGoodsPacking/save/excel",
  //我的发货单-明细分页查询
  WhShipmentsListDetPageList:  HY_BASEURL + "/wh/bizWhMySendGoodsRecord/query/my/page",
  //我的发货单-发货单提交指令/wh/bizWhMySendGoods/send/command/{id}
  WhShipmentsListCommand:  HY_BASEURL + "/wh/bizWhMySendGoods/send/command",
	
	//自提单-excel读取 /wh/bizWhMySendGoods/batch/read/excel  Excel读取
	WhTransferOrderExcelRead:  HY_BASEURL + "/wh/bizWhMySendGoods/batch/read/excel",
	// 自提单- /wh/bizWhMySendGoods/update/edit PUT 编辑更新
	WhTransferOrderEditUpdate:  HY_BASEURL + "/wh/bizWhMySendGoods/update/edit",
	// 自提单-/wh/bizWhMySendGoods/query/edit/{editId}  编辑查询
	WhTransferOrderEditSer:  HY_BASEURL + "/wh/bizWhMySendGoods/query/edit",
	
	//自提单，批量新增 /wh/bizWhMySendGoods/batch/insert
	WhShipmentsOrderBatchUpdate:  HY_BASEURL + "/wh/bizWhMySendGoods/batch/insert",
	//自提单，单条 新增/wh/bizWhMySendGoods/update/insert
	WhShipmentsOrderAdd:  HY_BASEURL + "/wh/bizWhMySendGoods/update/insert",
	//自提单，提交 /wh/bizWhMySendGoods/commit
	WhShipmentsOrderCommit:  HY_BASEURL + "/wh/bizWhMySendGoods/commit",
	//自提单 撤销 /wh/bizWhMySendGoods/update/return  {"ids":[]}
	WhShipmentsOrderReturn:  HY_BASEURL + "/wh/bizWhMySendGoods/update/return",
	
	//更新数据-自提单：/wh/bizWhMySendGoods/modify/info
	WhInShipmentEditModify: HY_BASEURL+"/wh/bizWhMySendGoods/modify/info",
	
	// 批量修改-出车方式 /wh/bizWhMySendGoods/update/cartmstype {"ids":[],"carTmsType":""}
	WhInShipmentsBatchEditTmsType:  HY_BASEURL + "/wh/bizWhMySendGoods/update/cartmstype",
	
	//自提单-留言
	// /wh/bizWhMySendGoods/save/comment   PUT {"id":"","comment":""} 保存
	WhSendGoodsCommentSave:  HY_BASEURL + "/wh/bizWhMySendGoods/save/comment",
	// /wh/bizWhMySendGoods/query/comment/{sendId}  GET
	WhSendGoodsCommentDet:  HY_BASEURL + "/wh/bizWhMySendGoods/query/comment",
	//自提-导出 /wh/bizWhMySendGoods/export/{userId}
	WhInShipmentsExport: HY_BASEURL+"/wh/bizWhMySendGoods/export",
	
	//自提-批量导入附件-匹配 /wh/bizWhMySendGoods/attachment/matching  PUT  {"matchAttachments":[{"type":"","fileName":"","url":""}],"ids":[]}
	WhShipmentsUploadFileMatch: HY_BASEURL+"/wh/bizWhMySendGoods/attachment/matching",
	//自提-批量导入附件-关联  /wh/bizWhMySendGoods/upload/matching/attachment 上传附件 [{"type":"","fileName":"","relationId":""}]
	WhShipmentsUploadFileMatchRel: HY_BASEURL+"/wh/bizWhMySendGoods/upload/matching/attachment",
	
	//转运管理
	// 转运入库
	// 分页 /wh/bizWhTransfer/query/page
	WhTransferPageList:  HY_BASEURL + "/wh/bizWhTransfer/query/page",
	// 删除 /wh/bizWhTransfer/delete/{id}
	WhTransferDel:  HY_BASEURL + "/wh/bizWhTransfer/delete",
	// 新增 /wh/bizWhTransfer/add
	WhTransferAdd:  HY_BASEURL + "/wh/bizWhTransfer/add",
	// 明细删除 /wh/bizWhTransferRecord/delete/{id}
	WhTransferDetDel:  HY_BASEURL + "/wh/bizWhTransferRecord/delete",
	// 编辑 /wh/bizWhTransfer/edit
	WhTransferEdit:  HY_BASEURL + "/wh/bizWhTransfer/edit",
	// 撤销 转运入库撤销: /wh/bizWhTransfer/update/remove  PUT  {"ids":[]}
		// 转运入库明细撤销:/wh/bizWhTransferRecord/update/remove  PUT  {"id":""}
	WhTransferRemove:  HY_BASEURL + "/wh/bizWhTransfer/update/remove",
	
	// 编辑明细查询 /wh/bizWhTransferRecord/query/List/{trId}
	WhTransferDetQuery:  HY_BASEURL + "/wh/bizWhTransferRecord/query/List",
	// 编辑查询 /wh/bizWhTransfer/query/edit/{trId}
	WhTransferEditQuery:  HY_BASEURL + "/wh/bizWhTransfer/query/edit",
	// 提交  /wh/bizWhTransfer/commit
	WhTransferCommit:  HY_BASEURL + "/wh/bizWhTransfer/commit",
	//明细分页查询 /wh/bizWhTransferRecord/query/page
	WhTransferDetPageList:  HY_BASEURL + "/wh/bizWhTransferRecord/query/page",
	//转运库存 /wh/bizWhTransferRecord/query/stock/page
	WhTransferStockPageList:  HY_BASEURL + "/wh/bizWhTransferRecord/query/stock/page",
	//查询入库明细的出库信息 /wh/bizWhTransferOutRecord/query/stock/out/page
	WhTransferStockOutPageList:  HY_BASEURL + "/wh/bizWhTransferOutRecord/query/stock/out/page",
	
	//转运出库
	// 转运状态统计
	// /wh/bizWhTransferOut/status/statistics    GET
	WhTransferOutStatusCount:  HY_BASEURL + "/wh/bizWhTransferOut/status/statistics",
	// 分页 /wh/bizWhTransferOut/query/page
	WhTransferOutPageList:  HY_BASEURL + "/wh/bizWhTransferOut/query/page",
	// 删除 /wh/bizWhTransferOut/delete/{trOutId}
	WhTransferOutDel:  HY_BASEURL + "/wh/bizWhTransferOut/delete",
	// 撤销 转运出库撤销: /wh/bizWhTransferOut/update/remove  PUT  {"ids":[]}
// 转运出库明细撤销:/wh/bizWhTransferOutRecord/update/remove  PUT  {"id":""}
	WhTransferOutRemove:  HY_BASEURL + "/wh/bizWhTransferOut/update/remove",
	// 新增 /wh/bizWhTransferOut/add
	WhTransferOutAdd:  HY_BASEURL + "/wh/bizWhTransferOut/add",
	// 明细删除 /wh/bizWhTransferOutRecord/delete/{trOutRecordId}
	WhTransferOutDetDel:  HY_BASEURL + "/wh/bizWhTransferOutRecord/delete",
	// 新增编辑单条明细 /wh/bizWhTransferOutRecord/eidt/{trOutId}/record
	WhTransferOutDetAdd:  HY_BASEURL + "/wh/bizWhTransferOutRecord/eidt",
	// 编辑 /wh/bizWhTransferOut/eidt
	WhTransferOutEdit:  HY_BASEURL + "/wh/bizWhTransferOut/eidt",
	// 根据主表ID查询明细 /wh/bizWhTransferOutRecord/query/List/{trOutId}
	WhTransferOutDetQuery:  HY_BASEURL + "/wh/bizWhTransferOutRecord/query/List",
	// 编辑查询 /wh/bizWhTransferOut/query/edit/{trOutId}
	WhTransferOutEditQuery:  HY_BASEURL + "/wh/bizWhTransferOut/query/edit",
	// 提交  /wh/bizWhTransferOut/commit
	WhTransferOutCommit:  HY_BASEURL + "/wh/bizWhTransferOut/commit",
	// 查询 fba地址 /wh/bizWhFc/query/fba
	WhTransferQueryFba:  HY_BASEURL + "/wh/bizWhFc/query/fba",
	
	//出库地址保存新增
	// 地址分页 /wh/bizWhFc/query/my/page
	WhTransferAddressPageList:  HY_BASEURL + "/wh/bizWhFc/query/my/page",
	//地址删除 /wh/bizWhFc/delete/{id}
	WhTransferAddressDel:  HY_BASEURL + "/wh/bizWhFc/delete",
	//地址添加 /wh/bizWhFc/add/wh/fc  
	WhTransferAddressAdd:  HY_BASEURL + "/wh/bizWhFc/add/wh/fc",
	
	//转运出库-目的地更新
	// /wh/bizWhTransferOut/update/fba/address  PUT  {"id":"","outWhCode":""}
	WhTransferUpdataAddress:  HY_BASEURL + "/wh/bizWhTransferOut/update/fba/address",
	//自提-目的地更新
	// /wh/bizWhMySendGoods/update/fba/address  PUT {"id":"","toWhNo":""}  自提
	WhShipmentUpdataAddress:  HY_BASEURL + "/wh/bizWhMySendGoods/update/fba/address",
	
	//转运bol下载
	// /wh/bizWhTransferOut/download/bol/{id}  GET 
	WhTransferOutDownloadBol:  HY_BASEURL + "/wh/bizWhTransferOut/download/bol",
	//自提bol下载
	// /wh/bizWhMySendGoods/download/bol/{id}  GET 
	WhShipmentDownloadBol:  HY_BASEURL + "/wh/bizWhMySendGoods/download/bol",
	
	//预约出库 
	// 分页 /wh/bizWhTransferOutPlanBatch/query/page
	WhTransferPlanOutWhPageList:  HY_BASEURL + "/wh/bizWhTransferOutPlanBatch/query/page",
	//新增 /wh/bizWhTransferOutPlanBatch/add/plans
	WhTransferPlanOutWhAdd:  HY_BASEURL + "/wh/bizWhTransferOutPlanBatch/add/plans",
	//删除 /wh/bizWhTransferOutPlanBatch/delete
	WhTransferPlanOutWhDel:  HY_BASEURL + "/wh/bizWhTransferOutPlanBatch/delete",
	//删除明细 /wh/bizWhTransferOutPlan/remove
	WhTransferPlanOutWhDetDel:  HY_BASEURL + "/wh/bizWhTransferOutPlan/remove",
	//更新 /wh/bizWhTransferOutPlanBatch/update/edit
	WhTransferPlanOutWhUpdate:  HY_BASEURL + "/wh/bizWhTransferOutPlanBatch/update/edit",
	//编辑查询 /wh/bizWhTransferOutPlanBatch/query/edit/{id}
	WhTransferPlanOutWhQuery:  HY_BASEURL + "/wh/bizWhTransferOutPlanBatch/query/edit",
	//预约完成 /wh/bizWhTransferOutPlanBatch/plan/complate
	WhTransferPlanComplate:  HY_BASEURL + "/wh/bizWhTransferOutPlanBatch/plan/complate",
	//提货单 /wh/bizWhTransferOutPlanBatch/print/bol
	WhTransferPlanBol:  HY_BASEURL + "/wh/bizWhTransferOutPlanBatch/print/bol",
	
	//可预约列表 /wh/bizWhTransferRecord/query/plan/page 
	WhTransferPlanSelPageList:  HY_BASEURL + "/wh/bizWhTransferRecord/query/plan/page",
	
	// 快速转运
	// 分页 /wh/bizWhTransportFba/query/page
	WhTransferFastPageList: HY_BASEURL + "/wh/bizWhTransportFba/query/page",
	// 删除
	// /wh/bizWhTransportFba/delete/{id}
	WhTransferFastDel: HY_BASEURL + "/wh/bizWhTransportFba/delete",
	//单条新增
	// /wh/bizWhTransportFba/add/transprot
	WhTransferFastAdd: HY_BASEURL + "/wh/bizWhTransportFba/add/transprot",
	//批量提交
	// /wh/bizWhTransportFba/batch/add/transprot
	WhTransferFastBatchAdd: HY_BASEURL + "/wh/bizWhTransportFba/batch/add/transprot",
	//读取excel
	// /wh/bizWhTransportFba/batch/read/excel
	WhTransferFastExcelRead: HY_BASEURL + "/wh/bizWhTransportFba/batch/read/excel",
	//编辑提交
	// /wh/bizWhTransportFba/update/eidt
	WhTransferFastEdit: HY_BASEURL + "/wh/bizWhTransportFba/update/eidt",
	//编辑查询
	// /wh/bizWhTransportFba/query/edit/{id}
	WhTransferFastQuery: HY_BASEURL + "/wh/bizWhTransportFba/query/edit",
	
	// 快速转运-删除预约 /wh/bizWhTransferOutPlanBatch/delete PUT {"ids":[]}
	
	//快速转运-预约明细 /wh/bizWhTransferOutPlanBatch/query/list
	WhTransferFastPlanList: HY_BASEURL + "/wh/bizWhTransferOutPlanBatch/query/list",

	
  //仓库-附件上传
  //新增 /wh/bizWhFile/add/{relationId}
  WhFileUploadAdd:  HY_BASEURL + "/wh/bizWhFile/add",
  //删除 /wh/bizWhFile/delete/{id}
  WhFileUploadDel:  HY_BASEURL + "/wh/bizWhFile/delete",
  //查询 /wh/bizWhFile/query/{relationId}
  WhFileUploadList:  HY_BASEURL + "/wh/bizWhFile/query",

  //一件代发
  //一件代发-分页查询
  WhDropShippingPageList:  HY_BASEURL + "/wh/bizWhMySendExpress/query/page",
  //一件代发-新增编辑
  WhDropShippingAdd:  HY_BASEURL + "/wh/bizWhMySendExpress/updateInsert",
  //一件代发-导入
  WhDropShippingExUpload:  HY_BASEURL + "/wh/bizWhMySendExpress/batch/read/excel",
  //一件代发-删除 /wh/bizWhMySendExpress/delete/{id}
  WhDropShippingDel:  HY_BASEURL + "/wh/bizWhMySendExpress/delete",
	///wh/bizWhMySendExpress/revoke  PUT   {"ids":[]}  一件代发撤销
	WhDropShippingRevoke:  HY_BASEURL + "/wh/bizWhMySendExpress/revoke",
  //一件代发-提交
  WhDropShippingSubmit:  HY_BASEURL + "/wh/bizWhMySendExpress/commit",
	//取消发货 /wh/bizWhMySendExpress/cancel/{id}
	WhDropShippingCancel: HY_BASEURL+"/wh/bizWhMySendExpress/cancel",
	//一件代发-暂停 /wh/bizWhMySendExpress/stop
	WhDropShippingStop: HY_BASEURL+"/wh/bizWhMySendExpress/stop",
	//一件代发-暂停恢复 /wh/bizWhMySendExpress/recovery/stop
	whDropShippingStopRecovery: HY_BASEURL+"/wh/bizWhMySendExpress/recovery/stop",
	//录入运单-单件 /wh/bizWhMySendExpress/sys/writ/tracking/{exprId}
	WhDropShippingWrTracking: HY_BASEURL+"/wh/bizWhMySendExpress/writ/tracking",
	//一件代发-新-导入保存
	WhDropShippingBatchInsert:  HY_BASEURL + "/wh/bizWhMySendExpress/batch/insert",
	//一件代发-单个-保存 /wh/bizWhMySendExpress/update/insert
	WhDropShippingSingleInsert:  HY_BASEURL + "/wh/bizWhMySendExpress/update/insert",
	//一件代发-单个-保存 /wh/bizWhMySendExpress/update/edit
	WhDropShippingSingleEdit:  HY_BASEURL + "/wh/bizWhMySendExpress/update/edit",
	//一件代发-异常件-重新提交 /wh/bizWhMySendExpress/resubmit/check/commit  {"ids":[],"zoneWhNo":""}
	WhDropShippingReSubmit:  HY_BASEURL + "/wh/bizWhMySendExpress/resubmit/check/commit",
	//一件代发-移至异常 /wh/bizWhMySendExpress/pending/to/fail PUT {"ids":[]}
	WhDropShippingMoveFail:  HY_BASEURL + "/wh/bizWhMySendExpress/pending/to/fail",
	
	
	//一件代发-单个-编辑查询 /wh/bizWhMySendExpress/query/edit/{id}
	WhDropShippingDetById:  HY_BASEURL + "/wh/bizWhMySendExpress/query/edit",
	
	//一件代发-明细-状态统计 /wh/bizWhMySendExpress/query/statistics  PUT {}
	WhDropShippingStatusStatistics: HY_BASEURL+"/wh/bizWhMySendExpress/query/statistics",
	
	
	//打单费率
	//打单费率查询 /wh/bizWhLabelInfo/rates/{exprId}
	WhPriterOrderRate: HY_BASEURL+"/wh/bizWhLabelInfo/rates",
	//打单费率查询 ups /wh/bizWhLabelInfo/rates/{exprId}
	WhPriterOrderUpsRate: HY_BASEURL+"/wh/bizWhLabelUpsInfo/rates",
	
	//UPS 渠道2，直接提交
	// /thirdLogistics/bizLgsThridLabelInfo/create/label  PUT  [1111111111111]
	WhPriterOrderUps2BatchCreate: HY_BASEURL+"/thirdLogistics/bizLgsThridLabelInfo/create/label",
	// //UPS 渠道2，费率查询
	// // /thirdLogistics/fuerthertry/bizLgsThridOrderInfo/wms/query/rate/{id} PUT 
	// WhPriterOrderUps2Rate: HY_BASEURL+"/thirdLogistics/fuerthertry/bizLgsThridOrderInfo/wms/query/rate",
	//UPS 渠道2，费率查询-新-去掉-fuerthertry
	// /thirdLogistics/fuerthertry/bizLgsThridOrderInfo/wms/query/rate/{id} PUT 
	WhPriterOrderUps2Rate: HY_BASEURL+"/thirdLogistics/bizLgsThridOrderInfo/wms/query/rate",
	//UPS 渠道2，lgs 列表 /thirdLogistics/bizLgsThridKey/query/list/vo  PUT {}
	WhPriterOrderUps2LgsList: HY_BASEURL+"/thirdLogistics/bizLgsThridKey/query/list/vo",
	//cope shipFrom /thirdLogistics/bizLgsThridOrderInfo/cope/shipfrom   Get
	WhPriterOrderCopeShipfrom: HY_BASEURL+"/thirdLogistics/bizLgsThridOrderInfo/cope/shipfrom",
	
	//打单明细
	//fedex
	//分页 /wh/bizWhLabelInfo/query/page
	WhPrtOrderLabelInfoPageList: HY_BASEURL+"/wh/bizWhLabelInfo/query/page",
	//取消运单 /wh/bizWhLabelInfo/label/cancel/{id}
	WhPrtOrderLabelInfoCancel: HY_BASEURL+"/wh/bizWhLabelInfo/label/cancel",
	//撤销打单 /wh/bizWhLabelInfo/label/remove
	WhPrtOrderLabelInfoRemove: HY_BASEURL+"/wh/bizWhLabelInfo/label/remove",
	//面单查询 /wh/bizWhLabelInfo/query/label
	WhPrtOrderLabelInfoQuery: HY_BASEURL+"/wh/bizWhLabelInfo/query/label",
	//编辑 服务类型 /wh/bizWhLabelInfo/update/lable/serviceLevel    PUT    {"id":"数据ID","carrierServiceLevel":"服务类型"}
	WhPrtOrderLabelServiceLevel: HY_BASEURL+"/wh/bizWhLabelInfo/update/lable/serviceLevel",
	
	//ups
	//分页
	WhPrtOrderLabelUpsInfoPageList: HY_BASEURL+"/wh/bizWhLabelUpsInfo/query/page",
	//取消运单 /wh/bizWhLabelInfo/label/cancel/{id}
	WhPrtOrderLabelUpsInfoCancel: HY_BASEURL+"/wh/bizWhLabelUpsInfo/label/cancel",
	//撤销打单 /wh/bizWhLabelInfo/label/remove
	WhPrtOrderLabelUpsInfoRemove: HY_BASEURL+"/wh/bizWhLabelUpsInfo/label/remove",
	//面单查询 /wh/bizWhLabelInfo/query/label
	WhPrtOrderLabelUpsInfoQuery: HY_BASEURL+"/wh/bizWhLabelUpsInfo/query/label",
	//编辑 服务类型 /wh/bizWhLabelInfo/update/lable/serviceLevel    PUT    {"id":"数据ID","carrierServiceLevel":"服务类型"}
	WhPrtOrderLabelUpsServiceLevel: HY_BASEURL+"/wh/bizWhLabelUpsInfo/update/lable/serviceLevel",
	
	//发货单轨迹查询 /wh/bizWhMySendExpress/query/lgs/exper/track
	WhExpLgsTrackList: HY_BASEURL+"/wh/bizWhMySendExpress/query/lgs/exper/track",
	// 轨迹查询 /wh/lgs/fedex/api/query/lgs/track
	WhFedexLgsTrackQuery: HY_BASEURL+"/wh/lgs/fedex/api/query/lgs/track",
	//UPS 轨迹 /wh/lgs/ups/api/get/tracking?trackNo=
	lgsUpsTracking: HY_BASEURL +"/wh/lgs/ups/api/get/tracking",
	
	///wh/lgs/fedex/api/validata/address   地址校验  PUT [{"streetLines":[],"city":"","stateOrProvinceCode":"","postalCode":"","countryCode":""}]
	WhExpressAddressValidata: HY_BASEURL +"/wh/lgs/fedex/api/validata/address",
	// /wh/lgs/fedex/api/query/zone  PUT {"fromZip":"","toZip":""}
	WhExpressAddressZone: HY_BASEURL +"/wh/lgs/fedex/api/query/zone",
	
	//退货换标
	//分页查询 /wh/bizWhMyReturn/query/page
	WhExchangeReturnPageList: HY_BASEURL+"/wh/bizWhMyReturn/query/page",
	//新增编辑 /wh/bizWhMyReturn/update/insert
	WhExchangeReturnUpdate: HY_BASEURL+"/wh/bizWhMyReturn/update/insert",
	//详情 /wh/bizWhMyReturn/query/detail/{id}
	WhExchangeReturnDet:  HY_BASEURL + "/wh/bizWhMyReturn/query/detail",
	//删除-明细-记录 /wh/bizWhMyReturnRecord/delete/{id}
	WhExchangeReturnDel:  HY_BASEURL + "/wh/bizWhMyReturnRecord/delete",
	//导入-读取 /wh/bizWhMyReturn/reader/excel POST 
	WhExchangeReturnExcReader:  HY_BASEURL + "/wh/bizWhMyReturn/reader/excel",
	//导入-保存 /wh/bizWhMyReturn/save/excel/{userId} PUT 导入
	WhExchangeReturnExcSave:  HY_BASEURL + "/wh/bizWhMyReturn/save/excel",
	//单个查询 /wh/bizWhMyReturnRecord/{returnId}/record GET
	WhExchangeReturnRecDet:  HY_BASEURL + "/wh/bizWhMyReturnRecord",
	
	//无主货管理
	// 2：/wh/bizWhUnclaimedGoods/query/edit/{id} GET 编辑查询
	WhUnclaimedGoodsEditQuery: HY_BASEURL + "/wh/bizWhUnclaimedGoods/query/edit",
	// 3：/wh/bizWhUnclaimedGoods/add/edit PUT
	WhUnclaimedGoodsEdit: HY_BASEURL + "/wh/bizWhUnclaimedGoods/add/edit",
	// 5：/wh/bizWhUnclaimedGoods/query/page  PUT {"offset":"0","limit":"10"}
	WhUnclaimedGoodsPageList: HY_BASEURL + "/wh/bizWhUnclaimedGoods/query/page",
	// /wh/bizWhUnclaimedGoods/confrim/unclaimed/{userId}   认领  PUT  {"ids":[]}
	WhUnclaimedGoodsClaim: HY_BASEURL + "/wh/bizWhUnclaimedGoods/confrim/unclaimed",
	
	//本人待认领 /wh/bizWhUnclaimedGoods/query/my/page
	WhUnclaimedGoodsSelfPageList: HY_BASEURL + "/wh/bizWhUnclaimedGoods/query/my/page",
	
	//撤销 /wh/bizWhMyReturn/return  PUT  {"id":""}   撤销
	WhExchangeReturnRevocation:  HY_BASEURL + "/wh/bizWhMyReturn/return",
	//删除 /wh/bizWhMyReturn/delete/{returnId}  DELTE  删除
	WhExchangeReturnBaseDel:  HY_BASEURL + "/wh/bizWhMyReturn/delete",
  
  //工单
  //删除
  ///wh/bizWhWorkOrder/delete/{id}
  WhWorkOrderDel:  HY_BASEURL + "/wh/bizWhWorkOrder/delete",
  //单条查询
  // /wh/bizWhWorkOrder/query/{id}
  WhWorkOrderDet:  HY_BASEURL + "/wh/bizWhWorkOrder/query",
  //分页查询
  // /wh/bizWhWorkOrder/query/my/page
  WhWorkOrderPageList:  HY_BASEURL + "/wh/bizWhWorkOrder/query/my/page",
  //提交工单-新增-编辑
  // /wh/bizWhWorkOrder/updateInsert
  WhWorkOrderAdd:  HY_BASEURL + "/wh/bizWhWorkOrder/updateInsert",
  // 查询处理日志
  // /wh/bizWhWorkOrder/query/log/{id}
  WhWorkOrderLog:  HY_BASEURL + "/wh/bizWhWorkOrder/query/log",
	//编辑查询 /wh/bizWhWorkOrder/edit/query/{id}
	WhWorkOrderEditDet:  HY_BASEURL + "/wh/bizWhWorkOrder/edit/query",
	//提交用户工单 /wh/bizWhWorkOrder/commit/{id}
	WhWorkOrderSubmit:  HY_BASEURL + "/wh/bizWhWorkOrder/commit",
	
	//增值服务工单-分页
	WhWorkAddServicePageList:  HY_BASEURL + "/wh/bizWhWorkOrder/query/addsf/page",
	
	//工单-增值服务明细 /wh/bizWhMyAddService/get/workId/{workId} GET
	WhWorkAddServiceDet:  HY_BASEURL + "/wh/bizWhMyAddService/get/workId",
	//删除明细 /wh/bizWhMyAddService/del/{id}  DELETE
	WhWorkAddServiceDetDel:  HY_BASEURL + "/wh/bizWhMyAddService/del",
	//查询处理日志 /wh/bizWhWorkOrder/query/log/{id}
	WhWorkOrdeLog: HY_BASEURL+"/wh/bizWhWorkOrder/query/log",
  
  // 异常件
  // 分页
  WhExceptionalPageList:  HY_BASEURL + "/wh/bizWhExceReturn/query/page",
  
  //报表
  // SKU库存信息统计
  WhReportSkuStockPageList: HY_BASEURL +"/wh/bizWhReportSkuDayStock/query/page",
	WhReportSkuGroupStockPageList: HY_BASEURL +"/wh/bizWhReportSkuDayStock/query/group/sku/page",
	//sku库存趋势统计 /wh/bizWhReportSkuDayStock/query/group/sku/chart
	WhReportSkuCharts: HY_BASEURL +"/wh/bizWhReportSkuDayStock/query/group/sku/chart",
	
	//出库
	WhOutBill: HY_BASEURL +"/wh/bizWhMySkuStockSeq/query/my/out/bill",
	//代理用户
	//分页 /wh/bizWhProxyUser/query/page
	agentUserPageList: HY_BASEURL +"/wh/bizWhProxyUser/query/page",
	//解除绑定 /wh/bizWhProxyUser/unbind
	agentUserUnbind: HY_BASEURL +"/wh/bizWhProxyUser/unbind",
	// 设置代理用户 /wh/bizWhProxyUser/add/proxyuser
	agentUserAdd: HY_BASEURL +"/wh/bizWhProxyUser/add/proxyuser",
	// 查询我的代理客户下拉列表 /wh/bizWhProxyUser/query/my/proxyuser
	agentUserQueryMy: HY_BASEURL +"/wh/bizWhProxyUser/query/my/all/proxyuser",
	
	//代理-仓租汇总 /wh/bizWhFeeRecordProxy/query/page
	agentWhMyFeePageList: HY_BASEURL +"/wh/bizWhFeeRecordProxy/query/page",
	//代理-仓租明细 /wh/bizWhFeeRecordProxy/statistics/query/page
	agentWhCostStatisticsPageList: HY_BASEURL +"/wh/bizWhFeeRecordProxy/statistics/query/page",
	
	//UPS授权 
	//获取key /wh/lgs/ups/api/get/key GET
	upsAuthGetKey: HY_BASEURL +"/wh/lgs/ups/api/get/key",
	//传值code /wh/lgs/ups/api/get/token/{keyId}?code=
	upsAuthPostCode: HY_BASEURL +"/wh/lgs/ups/api/get/token",
	//UPS 轨迹 /wh/lgs/ups/api/get/tracking?trackNo=
	lgsUpsTracking: HY_BASEURL +"/wh/lgs/ups/api/get/tracking",
	
	
	//amazon
	//sp api 授权
	amazonSpAuth: HY_BASEURL +"/wh/bizWhAmazonAuth/auth",
	//授权分页查询
	amazonAuthPageList: HY_BASEURL +"/wh/bizWhAmazonAuth/query/page",
	//编辑别名/wh/bizWhAmazonAuth/update/seller/name
	amazonAuthUpdateName: HY_BASEURL +"/wh/bizWhAmazonAuth/update/seller/name",
	
	//amazon订单
	//拉取订单
	///wh/bizWhAmazonOrder/pull/amazon/orders
	AmazonOrderPull: HY_BASEURL +"/wh/bizWhAmazonOrder/pull/amazon/orders",
	//分页查询拉取订单
	AmazonOrderPullPageList: HY_BASEURL +"/wh/bizWhAmazonOrder/query/pull/page",
	//更新商品信息 /wh/bizWhAmazonOrder/update/items/{id}
	AmazonOrderUpdateItem: HY_BASEURL +"/wh/bizWhAmazonOrder/update/items",
	//更新地址 /wh/bizWhAmazonOrder/update/address/{id}
	AmazonOrderUpdateAddress: HY_BASEURL +"/wh/bizWhAmazonOrder/update/address",
	//更新购买人信息 /wh/bizWhAmazonOrder/update/buyinfo/{id}
	AmazonOrderUpdateBuyinfo: HY_BASEURL +"/wh/bizWhAmazonOrder/update/buyinfo",
	
	//pdf 拆分
	WhPdfSplit: HY_BASEURL+"/wh/download/v2/pdf/split/to/base64",
	//pdf旋转 /wh/download/v2/pdf/degrees   PUT  {"base64":"","degrees":""}
	WhPdfRotate: HY_BASEURL+"/wh/download/v2/pdf/degrees",
	//pdf合并 /wh/download/v2/batch/pdf/addpage
	WhPdfMerge: HY_BASEURL+"/wh/download/v2/batch/pdf/addpage",
	
	//面单匹配保存
	//根据面单PDF名称匹配代发单 /wh/bizWhMySendExpress/matching/order/label/url?workNo=
	whMatchingLabel: HY_BASEURL +"/wh/bizWhMySendExpress/matching/order/label/url",
	
	// 保存匹配信息 /wh/bizWhMySendExpress/save/matching/label/url
	whSaveMatchingLabel: HY_BASEURL +"/wh/bizWhMySendExpress/save/matching/label/url",
	
	//根据运单号，面单自动识别
	whMatchingLabelByTrackingNumber: HY_BASEURL +"/wh/bizWhMySendExpress/pdf/label/match/by/trucking",
	//根据运单号，面单自动识别-新 /wh/bizWhMySendExpressPdfMatch/add/match/pdf
	whMatchingLabelByTrackingNumberPdf: HY_BASEURL +"/wh/bizWhMySendExpressPdfMatch/add/match/pdf",
	//根据运单号，面单自动识别-查询 /wh/bizWhMySendExpressPdfMatch/query/record/page  PUT {"parentId":111111}
	whMatchingLabelPdfRec: HY_BASEURL +"/wh/bizWhMySendExpressPdfMatch/query/record/page",
	//根据运单号，面单自动识别-查询全部 /wh/bizWhMySendExpressPdfMatch/query/source/page 
	whMatchingLabelPdfSourcePage: HY_BASEURL +"/wh/bizWhMySendExpressPdfMatch/query/source/page",
	//根据运单号，面单自动识别-查询-合计 /wh/bizWhMySendExpressPdfMatch/query/record/total/{parentId}
	whMatchingLabelPdfRecTotal: HY_BASEURL +"/wh/bizWhMySendExpressPdfMatch/query/record/total",
	//重置匹配次数 /wh/bizWhMySendExpressPdfMatch/update/resReset/{id}/matchs  PUT {}
	whMatchingResetNum: HY_BASEURL +"/wh/bizWhMySendExpressPdfMatch/update/resReset",
	
	//账户充值信息
	//客户充值新增编辑 /wh/bizWhRechargeVerify/update
	WhRechargeVerifyUpdate: HY_BASEURL +"/wh/bizWhRechargeVerify/update",
	//客户端删除充值记录 /wh/bizWhRechargeVerify/delete/{id}
	WhRechargeVerifyDelete: HY_BASEURL +"/wh/bizWhRechargeVerify/delete",
	//客户端查询充值记录 /wh/bizWhRechargeVerify/query/page
	WhRechargeVerifyPageList: HY_BASEURL +"/wh/bizWhRechargeVerify/query/page",
	//客户端账户信息查询 /wh/bizWhAccount/query/user/account
	WhAccountUser: HY_BASEURL +"/wh/bizWhAccount/query/user/account",
	//客户端账户消费记录分页查询 /wh/bizWhAccountRecord/query/page
	WhAccountRecordList: HY_BASEURL +"/wh/bizWhAccountRecord/query/page",
	//客户端账户api key /wh/bizWhAccount/query/api/key  GET
	WhAccountApiKey: HY_BASEURL +"/wh/bizWhAccount/query/api/key",
	
	//第三方订单
	// 分页 /wh/bizWhThirdApiOrder/query/my/page
	WhThirdOrderPageList: HY_BASEURL +"/wh/bizWhThirdApiOrder/query/my/page",
	//第三方订单生产发货单 /wh/bizWhThirdApiOrder/work/order/command
	WhThirdOrderCommand: HY_BASEURL +"/wh/bizWhThirdApiOrder/work/order/command",
	//拉取新订单 /wh/bizWhThirdApiOrder/pull/ec/order   PUT {"status":"","saleOrderCodes":[],"warehouseOrderCodes":[]]
	WhThirdOrderPullOrder: HY_BASEURL+"/wh/bizWhThirdApiOrder/pull/ec/order",
	// 更新发货仓库 /wh/bizWhThirdApiOrder/update/whNo    {"ids":[],"warehouseCode":""} 
	WhThirdOrderUpdateWh: HY_BASEURL+"/wh/bizWhThirdApiOrder/update/whNo",
	//删除订单 /wh/bizWhThirdApiOrder/delete/orders  PUT  {"ids":[]}
	WhThirdOrderDel: HY_BASEURL+"/wh/bizWhThirdApiOrder/delete/orders",
	//撤销发货单 /wh/bizWhThirdApiOrder/remove/send/expr  PUT  [ids]
	WhThirdOrderRemoveSend: HY_BASEURL+"/wh/bizWhThirdApiOrder/remove/send/expr",
	//发货地址编辑
	WhThirdOrderReceiverEdit: HY_BASEURL+"/wh/bizWhThirdApiOrder/edit/info",
	
	//易仓拉取查询-仓库 /wh/bizWhThirdApiOrder/query/yicang/{userId}/warehouse PUT {}
	WhThirdYiCangWhQuery: HY_BASEURL+"/wh/bizWhThirdApiOrder/query/yicang/warehouse",
	//易仓 api 分页 /wh/bizWhYicangConf/query/page
	WhThirdYiCangConfPageList: HY_BASEURL+"/wh/bizWhYicangConf/query/page",
	//易仓 api 删除 /wh/bizWhYicangConf/delete/{id}
	WhThirdYiCangConfDel: HY_BASEURL+"/wh/bizWhYicangConf/delete",
	//易仓 api 更新 /wh/bizWhYicangConf/update/warehouse
	WhThirdYiCangConfUpdate: HY_BASEURL+"/wh/bizWhYicangConf/update/warehouse",
	//易仓 api 配置新增 /wh/bizWhYicangConf/add/update
	WhThirdYiCangConfAdd: HY_BASEURL+"/wh/bizWhYicangConf/add/update",
	//易仓库存同步 
	//分页 /wh/bizWhMySkuStock/query/yc/stock/page
	WhThirdYiCangInventorySync: HY_BASEURL+"/wh/bizWhMySkuStock/query/yc/stock/page",
	//提交同步 /wh/bizWhThirdApiOrder/yc/send/expr
	WhThirdYiCangSyncExpr: HY_BASEURL+"/wh/bizWhThirdApiOrder/yc/send/expr",
	//库存同步 /wh/bizWhMySkuStock/yc/stock/sync  PUT 
	WhThirdYiCangStockSync: HY_BASEURL+"/wh/bizWhMySkuStock/yc/stock/sync",
	//库存导入 /wh/bizWhMySkuStock/imp/ivs/{userId}  PUT  {"impInvVoList":[],"whNo":""}
	WhThirdYiCangStockImpIvs: HY_BASEURL+"/wh/bizWhMySkuStock/imp/ivs",
	
	//第三方应用
	// 1：/wh/ttp/get/authorization/token   PUT  {"tpCode":"","authCode":""}  授权
	// 新-/wh/ttp/update/authorization/token
	WhThirdAuthToken: HY_BASEURL+"/wh/ttp/update/authorization/token",
	// 2：/wh/ttp/anyc/shop  {"tpCode":""} 获取授权店铺
	WhThirdShopByTpCode: HY_BASEURL+"/wh/ttp/anyc/shop",
	// 3：/wh/ttp/query/shop/page  {"offset":0,"limit":10}
	WhThirdShopPageList: HY_BASEURL+"/wh/ttp/query/shop/page",
	// 4：/wh/ttp/sync/orders  {"tpCode":"","createTimeGe":"","createTimeLt":"","shopId":""}
	WhThirdTTPOrdersSync: HY_BASEURL+"/wh/ttp/sync/orders",
	// 5: /wh/ttp/query/orders/page {"offset":0,"limit":10} 订单分页查询
	WhThirdTTPOrderPageList: HY_BASEURL+"/wh/ttp/query/orders/page",
	// 6：/wh/ttp/add/expr/order  {"ids":[]}  生成一件代发发货单
	WhThirdTTPOrderAdd: HY_BASEURL+"/wh/ttp/add/expr/order",
	// /wh/ttp/query/authorization/config?tpCode= Get 
	WhThirdTTPAuthConfigQuery: HY_BASEURL+"/wh/ttp/query/authorization/config",
	// 面单 /wh/ttp/query/order/shipment/label   PUT  {"ids":[1802542918936014852]}  获取TK面单
	WhThirdTTPOrderLabel: HY_BASEURL+"/wh/ttp/query/order/shipment/label",
	
	// //快递面单
	// //查询费率 /thirdLogistics/fuerthertry/bizLgsThridOrderInfo/query/rate
	// WhFuerthertryQueryRate: HY_BASEURL +"/thirdLogistics/fuerthertry/bizLgsThridOrderInfo/query/rate",
	// //删除订单 /thirdLogistics/fuerthertry/bizLgsThridOrderInfo/delete/{id}
	// WhFuerthertryDel: HY_BASEURL +"/thirdLogistics/fuerthertry/bizLgsThridOrderInfo/delete",
	// //提交打单 /thirdLogistics/fuerthertry/bizLgsThridOrderInfo/commit/labels
	// WhFuerthertryCommit: HY_BASEURL +"/thirdLogistics/fuerthertry/bizLgsThridOrderInfo/commit/labels",
	// // 新增订单 /thirdLogistics/fuerthertry/bizLgsThridOrderInfo/add/order
	// WhFuerthertryAdd: HY_BASEURL +"/thirdLogistics/fuerthertry/bizLgsThridOrderInfo/add/order",
	// //订单编辑 /thirdLogistics/fuerthertry/bizLgsThridOrderInfo/edit/order
	// WhFuerthertryEdit: HY_BASEURL +"/thirdLogistics/fuerthertry/bizLgsThridOrderInfo/edit/order",
	// //订单分页 /thirdLogistics/fuerthertry/bizLgsThridOrderInfo/query/order/page
	// WhFuerthertryPageList: HY_BASEURL +"/thirdLogistics/fuerthertry/bizLgsThridOrderInfo/query/order/page",
	// // 订单进度明细分页查询 /thirdLogistics/bizLgsThridLabelInfo/query/label/page
	// WhFuerthertryDetPageList: HY_BASEURL +"/thirdLogistics/bizLgsThridLabelInfo/query/label/page",
	// // 取消面单 /thirdLogistics/bizLgsThridLabelInfo/cancel/label
	// WhThridLabelCancelOrder: HY_BASEURL +"/thirdLogistics/bizLgsThridLabelInfo/cancel/label",
	// // 撤销打单 /thirdLogistics/bizLgsThridLabelInfo/remove/label
	// WhThridLabelCancelPrinting: HY_BASEURL +"/thirdLogistics/bizLgsThridLabelInfo/remove/label",
	// // 校验地址 /thirdLogistics/fuerthertry/bizLgsThridOrderInfo/check/addr
	// WhThridOrderInfoCheckAddr: HY_BASEURL +"/thirdLogistics/fuerthertry/bizLgsThridOrderInfo/check/addr",
	
	// //导入运单模板 /thirdLogistics/fuerthertry/bizLgsThridOrderInfo/batch/read/excel
	// WhFuerthertryReadExcel: HY_BASEURL +"/thirdLogistics/fuerthertry/bizLgsThridOrderInfo/batch/read/excel",
	// //导入运单保存 /thirdLogistics/fuerthertry/bizLgsThridOrderInfo/save/excel
	// WhFuerthertryExcelSave: HY_BASEURL +"/thirdLogistics/fuerthertry/bizLgsThridOrderInfo/save/excel",
	
	//快递面单-新-去掉-fuerthertry
	//查询费率 /thirdLogistics/bizLgsThridOrderInfo/query/rate
	WhFuerthertryQueryRate: HY_BASEURL +"/thirdLogistics/bizLgsThridOrderInfo/query/rate",
	//删除订单 /thirdLogistics/bizLgsThridOrderInfo/delete/{id}
	WhFuerthertryDel: HY_BASEURL +"/thirdLogistics/bizLgsThridOrderInfo/delete",
	//提交打单 /thirdLogistics/bizLgsThridOrderInfo/commit/labels
	WhFuerthertryCommit: HY_BASEURL +"/thirdLogistics/bizLgsThridOrderInfo/commit/labels",
	// 新增订单 /thirdLogistics/bizLgsThridOrderInfo/add/order
	WhFuerthertryAdd: HY_BASEURL +"/thirdLogistics/bizLgsThridOrderInfo/add/order",
	//订单编辑 /thirdLogistics/bizLgsThridOrderInfo/edit/order
	WhFuerthertryEdit: HY_BASEURL +"/thirdLogistics/bizLgsThridOrderInfo/edit/order",
	//订单分页 /thirdLogistics/bizLgsThridOrderInfo/query/order/page
	WhFuerthertryPageList: HY_BASEURL +"/thirdLogistics/bizLgsThridOrderInfo/query/order/page",
	// 订单进度明细分页查询 /thirdLogistics/bizLgsThridLabelInfo/query/label/page
	WhFuerthertryDetPageList: HY_BASEURL +"/thirdLogistics/bizLgsThridLabelInfo/query/label/page",
	// 取消面单 /thirdLogistics/bizLgsThridLabelInfo/cancel/label
	WhThridLabelCancelOrder: HY_BASEURL +"/thirdLogistics/bizLgsThridLabelInfo/cancel/label",
	// 撤销打单 /thirdLogistics/bizLgsThridLabelInfo/remove/label
	WhThridLabelCancelPrinting: HY_BASEURL +"/thirdLogistics/bizLgsThridLabelInfo/remove/label",
	// 校验地址 /thirdLogistics/bizLgsThridOrderInfo/check/addr
	WhThridOrderInfoCheckAddr: HY_BASEURL +"/thirdLogistics/bizLgsThridOrderInfo/check/addr",
	//删除 /thirdLogistics/bizLgsThridLabelInfo/label/{id}  DELTE 
	WhThridLabelInfoDetDel: HY_BASEURL +"/thirdLogistics/bizLgsThridLabelInfo/label",
	//删除-批量 /thirdLogistics/bizLgsThridLabelInfo/batch/deletelabel  {"ids":[]}
	WhThridLabelInfoDetDelBatch: HY_BASEURL +"/thirdLogistics/bizLgsThridLabelInfo/batch/deletelabel",
	
	//导入运单模板 /thirdLogistics/bizLgsThridOrderInfo/batch/read/excel
	WhFuerthertryReadExcel: HY_BASEURL +"/thirdLogistics/bizLgsThridOrderInfo/batch/read/excel",
	//导入运单保存 /thirdLogistics/bizLgsThridOrderInfo/save/excel
	WhFuerthertryExcelSave: HY_BASEURL +"/thirdLogistics/bizLgsThridOrderInfo/save/excel",
	
	//预报-装箱单-打印箱唛 /wh/bizWhMyGoodsPacking/print/mark/{id} get  数据ID
	WhPrinterShippingMark: HY_BASEURL +"/wh/bizWhMyGoodsPacking/print/mark",
	
	//首页
	// /wh/bizCommon/SDS/current/day/out/statistics  一件代发 出库情况 PUT {}
	WhSDSDayOutStatistics: HY_BASEURL +"/wh/bizCommon/SDS/current/day/out/statistics",
	// /wh/bizCommon/STF/current/day/out/statistics 自提单 出库情况
	WhSTFDayOutStatistics: HY_BASEURL +"/wh/bizCommon/STF/current/day/out/statistics",
	// /wh/bizCommon/query/order/month/total  订单统计
	WhMonthOrderTotal: HY_BASEURL +"/wh/bizCommon/query/order/month/total",
	// 入库申请 /wh/bizCommon/in/wh/statistics   PUT {} 入库申请
	WhDayInWhStatistics: HY_BASEURL +"/wh/bizCommon/in/wh/statistics ",
	//转运 /wh/bizCommon/query/str/statistics
	WhSTRQueryStatistics: HY_BASEURL +"/wh/bizCommon/query/str/statistics",
	
	//订单统计报表
	//每月订单统计 /wh/bizCommon/query/order/year/total   每月总计 PUT {}
	WhOrderStatisticsByMon: HY_BASEURL +"/wh/bizCommon/query/order/year/total",
	//每天订单统计 /wh/bizCommon/query/order/day/detail  每天总计 PUT {}
	WhOrderStatisticsByDay: HY_BASEURL +"/wh/bizCommon/query/order/day/detail",
	
	///wh/bizCommon/addvs/wh/statistics  {} PUT  首页增值服务工单统计
	WhAddValueStatistics: HY_BASEURL +"/wh/bizCommon/addvs/wh/statistics",
	
	//仓库货量
	WhSkuDayStockCurrentVolume: HY_BASEURL +"/wh/bizWhReportSkuDayStock/query/current/volume",
	//未上线快递运单统计
	WhExpressTrunckNotOnlineTotal: HY_BASEURL +"/wh/bizWhMySendExpress/query/trunck/total",
	
	//未上线快递运单-分页 /wh/bizWhMySendExpress/query/trunck/info  PUT {"offset":"","limit":""}
	WhExpressTrunckNotOnlinePage: HY_BASEURL +"/wh/bizWhMySendExpress/query/trunck/info",
	
	//查询各个模块费用，根据ID
	// 入库预报   一件代发 自提  分批入库/出库 快速转运   退货
	// /wh/bizWhFeeBillOpRecord/query/commit/{id}/fees  GET
	WhFeeModleQueryById: HY_BASEURL+"/wh/bizWhFeeBillOpRecord/query/commit",
	
	//账单管理-新
	//一件代发操作
	// 一件代发操作:
	// /wh/bizWhFinBill/query/SDS/op/record/page  费用记录分页
	WhBillSdsOpRecPage:HY_BASEURL +"/wh/bizWhFinBill/query/SDS/op/record/page ",
	// /wh/bizWhFinBill/query/SDS/op/fee/total   费用合计
	WhBillSdsOpTotal:HY_BASEURL +"/wh/bizWhFinBill/query/SDS/op/fee/total",
	// 一件代发出库:
	// 明细查询:/wh/bizWhFinBill/wh/bizWhFinBill/query/SDS/out/record  PUT {"userId":"","whNo":"","opTimeStart":"","opTimeEnd":"","goodsSku":"","opStatus":"","isBill":""}
	WhBillSdsOutWhPage:HY_BASEURL +"/wh/bizWhFinBill/query/SDS/out/record",
	// 合计查询:/wh/bizWhFinBill/query/SDS/out/total PUT {"userId":"","whNo":"","opTimeStart":"","opTimeEnd":"","goodsSku":"","opStatus":"","isBill":""}
	WhBillSdsOutWhTotal:HY_BASEURL +"/wh/bizWhFinBill/query/SDS/out/total",
	// 附加费:
	// /warehouse/bizWhOpRecord/quer/page  明细记录
	WhBillAddFeePage:HY_BASEURL +"/warehouse/bizWhOpRecord/quer/page",
	// /warehouse/bizWhOpRecord/quer/record/total  合计
	WhBillAddFeeTotal:HY_BASEURL +"/warehouse/bizWhOpRecord/quer/record/total",
	// 仓租费:
	// /wh/bizWhFinBill/query/warehouse/fee/record/page  费用记录 {"userId":"","whNo":"","feeDateStart":"","feeDateEnd":""}
	WhBillWhFeePage:HY_BASEURL +"/wh/bizWhFinBill/query/warehouse/fee/record/page",
	// /wh/bizWhFinBil/query/warehouse/fee/totall  费用合计  {"userId":"","whNo":"","feeDateStart":"","feeDateEnd":""}
	WhBillWhFeeTotal:HY_BASEURL +"/wh/bizWhFinBill/query/warehouse/fee/total",
	//仓租费用-仓租费用分页
	WhFeeRecordPageList: HY_BASEURL+"/warehouse/bizWhFeeRecord/query/my/page",
	// 自提单：
	// /wh/bizWhFinBill/query/STF/op/record/page   {"userId":"","whNo":"","opTimeStart":"","opTimeEnd":"","opStatus":"","isBill":""}
	WhBillStfOpPage:HY_BASEURL +"/wh/bizWhFinBill/query/STF/op/record/page",
	// /wh/bizWhFinBill/query/STF/op/fee/total
	// {"userId":"","whNo":"","opTimeStart":"","opTimeEnd":"","opStatus":"","isBill":""}
	WhBillStfOpTotal:HY_BASEURL +"/wh/bizWhFinBill/query/STF/op/fee/total",
	// 收货入库：
	// /wh/bizWhFinBill/query/SCC/op/record/page
	WhBillSdsInWhPage:HY_BASEURL +"/wh/bizWhFinBill/query/SCC/op/record/page",
	// /wh/bizWhFinBill/query/SCC/op/fee/total
	WhBillSdsOutInTotal:HY_BASEURL +"/wh/bizWhFinBill/query/SCC/op/fee/total",
	
	//转运入库
	// /wh/bizWhFinBill/query/SCC/op/record/page
	WhBillTSCCInWhPage:HY_BASEURL +"/wh/bizWhFinBill/query/TSCC/op/record/page",
	// /wh/bizWhFinBill/query/SCC/op/fee/total
	WhBillTSCCOutInTotal:HY_BASEURL +"/wh/bizWhFinBill/query/TSCC/op/fee/total",
	//转运出库
	// /wh/bizWhFinBill/query/SCC/op/record/page
	WhBillSTRInWhPage:HY_BASEURL +"/wh/bizWhFinBill/query/STR/op/record/page",
	// /wh/bizWhFinBill/query/SCC/op/fee/total
	WhBillSTROutInTotal:HY_BASEURL +"/wh/bizWhFinBill/query/STR/op/fee/total",
	//增值服务
	// /wh/bizWhFinBill/query/SCC/op/record/page
	WhBillADDVSInWhPage:HY_BASEURL +"/wh/bizWhFinBill/query/ADDVS/op/record/page",
	// /wh/bizWhFinBill/query/SCC/op/fee/total
	WhBillADDVSOutInTotal:HY_BASEURL +"/wh/bizWhFinBill/query/ADDVS/op/fee/total",
	
	// 退货入库：
	WhBillSREInWhPage:HY_BASEURL +"/wh/bizWhFinBill/query/SRE/op/record/page",
	WhBillSREOutInTotal:HY_BASEURL +"/wh/bizWhFinBill/query/SRE/op/fee/total",
	
	// 快速转运：
	WhBillFTROrderPage:HY_BASEURL +"/wh/bizWhFinBill/query/FTR/op/record/page",
	WhBillFTROrderTotal:HY_BASEURL +"/wh/bizWhFinBill/query/FTR/op/fee/total",
	
	// 港口提柜 PCPU：
	WhBillPCPUPage:HY_BASEURL +"/wh/bizWhFinBill/query/PCPU/op/record/page",
	WhBillPCPUTotal:HY_BASEURL +"/wh/bizWhFinBill/query/PCPU/op/fee/total",
	
	// 尾程卡派 TRUCK：
	WhBillTRUCKPage:HY_BASEURL +"/wh/bizWhFinBill/query/TRUCK/op/record/page",
	WhBillTRUCKTotal:HY_BASEURL +"/wh/bizWhFinBill/query/TRUCK/op/fee/total",
	
	//账单首页
	// /wh/bizWhFinBill/query/all/fee/total  {"opTimeStart":"","opTimeEnd":""}
	// /wh/bizWhFinBill/query/warehouse/all/fee/total {"opTimeStart":"","opTimeEnd":""}
	// /wh/bizWhFinBill/query/warehouse/cus/all/fee/total {"whNo":"","opTimeStart":"","opTimeEnd":""}
	WhBillAllFeeTotal:HY_BASEURL +"/wh/bizWhFinBill/query/all/fee/total",
	WhBillAllFeeAndWh:HY_BASEURL +"/wh/bizWhFinBill/query/warehouse/all/fee/total",
	WhBillAllFeeAndWhByCus:HY_BASEURL +"/wh/bizWhFinBill/query/warehouse/cus/all/fee/total",
	// /wh/bizWhFinBill/query/cus/all/fee/total   
	// /wh/bizWhFinBill/query/cus/warehouse/all/fee/total
	WhBillAllFeeAndCus:HY_BASEURL +"/wh/bizWhFinBill/query/cus/all/fee/total",
	WhBillAllFeeAndCusByWh:HY_BASEURL +"/wh/bizWhFinBill/query/cus/warehouse/all/fee/total",
	
	// 客户端获取仓库列表： /wh/bizWhCusBind/query/client/wh  GET
	//旧
	// WhCusBindWhList:HY_BASEURL +"/wh/bizWhCusBind/query/client/wh",
	//-新 whNoPageList: HY_BASEURL +"/wh/bizWhInfo/query/select/list",
	WhCusBindWhList:HY_BASEURL +"/wh/bizWhInfo/query/select/list",
	
	//第三方WMS配置
	// /thirdLogistics/wms/config/query/page PUT  配置分页查询
	WhThirdWmsConfigPageList: HY_BASEURL +"/thirdLogistics/wms/config/query/page",
	// /thirdLogistics/wms/config/add PUT 配置增加 {"userId":"","thridCode":"","whNo":"","thridWhNo":"","apiUrl":"","clientKey":"","clientSecret":""}
	// WhThirdWmsConfigAdd: HY_BASEURL +"/thirdLogistics/wms/config/add",
	// /thirdLogistics/wms/config/query/eidt/{id} GET 编辑查询
	WhThirdWmsConfigQuery: HY_BASEURL +"/thirdLogistics/wms/config/query/eidt",
	// /thirdLogistics/wms/config/edit PUT 编辑更新
	// WhThirdWmsConfigEdit: HY_BASEURL +"/thirdLogistics/wms/config/edit",
	// /thirdLogistics/wms/config/open/close  PUT {"id":""} 关闭或打开
	// WhThirdWmsConfigOpenClose: HY_BASEURL +"/thirdLogistics/wms/config/open/close",
	// /thirdLogistics/wms/delete/{id}  DELETE 删除配置
	// WhThirdWmsConfigDel: HY_BASEURL +"/thirdLogistics/wms/delete",
	// /thirdLogistics/wms/async/info/query/page PUT 同步信息查询
	WhThirdWmsAsyncInfoList: HY_BASEURL +"/thirdLogistics/wms/async/info/query/page",
	
	//服务管理，QA
	//分页 /wh/bizWhQa/query/page
	WhQARecPageList : HY_BASEURL +"/wh/bizWhQa/query/page",
	//删除 /wh/bizWhQa/delete/{id}
	WhQARecDel : HY_BASEURL +"/wh/bizWhQa/delete",
	//富文本获取 /wh/bizWhQaProgress/query/more/text?keyId=
	WhQARecMoreText : HY_BASEURL +"/wh/bizWhQaProgress/query/more/text",
	//新建QA /wh/bizWhQa/add/qa/{userId}
	WhQARecAdd : HY_BASEURL +"/wh/bizWhQa/add/qa",
	//添加进度说明 /wh/bizWhQaProgress/add/progress/{qaId}
	WhQARecProgressAdd : HY_BASEURL +"/wh/bizWhQaProgress/add/progress",
	// 进度详情获取 /wh/bizWhQaProgress/query/progress/{qaId}
	WhQARecProgressGet : HY_BASEURL +"/wh/bizWhQaProgress/query/progress",
	
	//增值服务分页  /wh/bizWhMyAddService/query/page
	WhValAddServicePageList : HY_BASEURL +"/wh/bizWhMyAddService/query/page",
	//增值服务 新增退货增值服务 /wh/bizWhMyAddService/add/return/{returnId}
	WhValAddServiceAdd : HY_BASEURL +"/wh/bizWhMyAddService/add/return",
	//查询关联ID增值服务工单 /wh/bizWhMyAddService/get/{relationId}
	WhValAddServiceGetByRel : HY_BASEURL +"/wh/bizWhMyAddService/get",
	//增值服务-新增-有关联Id /wh/bizWhMyAddService/add/biz/service  {"relationId":"","addServiceList":[],"attachments":[],"bizType":""}
	WhValAddServiceBizAdd : HY_BASEURL +"/wh/bizWhMyAddService/add/biz/service",
	//增值服务-新增-没有关联Id /wh/bizWhMyAddService/add/other/service  {"addServiceList":[],"attachments":[],"whNo":"","remark":""}
	WhValAddServiceOtherAdd : HY_BASEURL +"/wh/bizWhMyAddService/add/other/service",
	
	//子账号
	//分页 /warehouse/bizWhCustomer/query/sub/page
	WhSubUserPageList: HY_BASEURL +"/warehouse/bizWhCustomer/query/sub/page",
	//子账户-新增  /wh/bizWhCustomerSub/create
	WhSubUserAdd: HY_BASEURL +"/wh/bizWhCustomerSub/create",
	//详情 /wh/bizWhCustomerSub/sub/info/{suUserId}
	WhSubUserInfo: HY_BASEURL +"/wh/bizWhCustomerSub/sub/info",
	
	//配置客户菜单
	//查询  /wh/bizWhClientMenu/query/sub/userMenu?subUserId=
	WhSubUserMenusQuery: HY_BASEURL +"/wh/bizWhClientMenu/query/sub/userMenu",
	//编辑 /wh/bizWhCustomerRoleMenu/updateRole/sub/{subUserId}  PUT
	WhSubUserMenusEdit: HY_BASEURL +"/wh/bizWhCustomerRoleMenu/updateRole/sub",
	//查询登录用户菜单  /wh/bizWhClientMenu/query/userMenu
	WhMenusQuery: HY_BASEURL +"/wh/bizWhClientMenu/query/userMenu",
	
	//分页 /wh/bizWhInfo/query/page
	WhWarehousePageList: HY_BASEURL +"/wh/bizWhInfo/query/page",
	
	// 通知消息查询
	//内容查询 /wh/bizCommon/query/more/text?keyId=
	WhNoticeMoreText: HY_BASEURL +"/wh/bizCommon/query/more/text",
	// 后端：/wh/bizWhNoticeMessage/query/notice/page
	// 前端：/wh/bizWhNoticeMessage/query/notice/page
	WhNoticeList: HY_BASEURL +"/wh/bizWhNoticeMessage/query/notice/page",
	//详情 /wh/bizWhNoticeMessage/query/notice/{id} GET
	WhNoticeDet: HY_BASEURL +"/wh/bizWhNoticeMessage/query/notice",
	
	//SKU关联
	//分页  /wh/bizWhMyGoodsSkuRelation/query/page
	WhSkuRelationPage: HY_BASEURL +"/wh/bizWhMyGoodsSkuRelation/query/page",
	//新增 /wh/bizWhMyGoodsSkuRelation/add/sku/relation
	WhSkuRelationAdd: HY_BASEURL +"/wh/bizWhMyGoodsSkuRelation/add/sku/relation",
	//编辑 /wh/bizWhMyGoodsSkuRelation/update/edit
	WhSkuRelationEdit: HY_BASEURL +"/wh/bizWhMyGoodsSkuRelation/update/edit",
	//删除 /wh/bizWhMyGoodsSkuRelation/delete
	WhSkuRelationDel: HY_BASEURL +"/wh/bizWhMyGoodsSkuRelation/delete",
	
	// 快递渠道-5：试算
	// /thirdLogistics/bizLgsBaseCarrierFeePrice/test/clac/fee  {"lgsKeyCode":"","carrier":""}
	WhCarrFeePriceTestClac: HY_BASEURL + "/thirdLogistics/bizLgsBaseCarrierFeePrice/test/clac/fee",
	//快递： 比价 /thirdLogistics/bizLgsBaseCarrierFeePrice/compare/clac/price
	WhCarrFeePriceComparePrice: HY_BASEURL + "/thirdLogistics/bizLgsBaseCarrierFeePrice/compare/clac/price",
	
	//显示配置-logo /wh/bizWhSysLogo/domain/list?domain=  获取
	WhSysShowConfig: HY_BASEURL + "/wh/bizWhSysLogo/domain/list",
	
	//代理用户
	// /wh/bizWhProxyUser/add/proxy/{userId}   PUT [1212121]
	// WhProxyUserAdd: HY_BASEURL + "/wh/bizWhProxyUser/add/proxy",
	// /wh/bizWhProxyUser/get/list/{userId} GET
	WhProxyUserList: HY_BASEURL + "/wh/bizWhProxyUser/get/list",
	// /wh/bizWhProxyUser/query/page/{userId} PUT
	// WhProxyUserPageList: HY_BASEURL + "/wh/bizWhProxyUser/query/page",
	// /wh/bizWhProxyUser/delete/proxy/{userId} PUT
	WhProxyUserDel: HY_BASEURL + "/wh/bizWhProxyUser/delete/proxy",
	
	
}

// const Vue =  {
//   install: function(Vue) {
//     // Do stuff
//   }
// }

// export default {
// 	urlConfig
// }
export default {
	install: function (Vue) {
		Object.defineProperty(Vue.prototype, '$urlConfig', { value: urlConfig });
	}
}
